import home from "./pages/home";
import hotel from "./pages/hotel";
import admin from "./pages/admin";
import support from "./pages/support";

import issue from "./pages/hotel/issue/issue.vue";
import login from "./pages/home/login.vue";

import team from "./pages/admin/team/team.vue";
import adminLogin from "./pages/admin/login/login.vue";
import registerHotel from "./pages/home/registerHotel.vue";

import register from "./pages/admin/register.vue";

import termsOfUse from "./components/terms/termsOfUse.vue";
import privateRumy from "./components/terms/privateRumy.vue";

const hasSubdomain =
  location.host.split(".").length > (location.host.search(".co.kr") !== -1 ? 3 : 2);

const routes: any[] = [
  ...home.map(item => ({ layout: "home-layout", ...item })),
  ...(hasSubdomain ? hotel : []).map((item: any) => ({ layout: "hotel-layout", ...item })),
  ...(hasSubdomain ? admin : []).map((item: any) => ({ layout: "admin-layout", ...item })),
  ...(hasSubdomain
    ? support
    : [
        {
          name: "support-root",
          path: "/support",
          redirect: "/",
        },
      ]
  ).map((item: any) => ({ layout: "support-layout", ...item })),
  {
    name: "root",
    path: "/",
    component: hasSubdomain ? issue : login,
    layout: hasSubdomain ? "hotel-layout" : "home-layout",
    layoutOptions: hasSubdomain ? {} : { fullwidth: true },
  },
  {
    name: "admin-root",
    path: "/admin",
    component: hasSubdomain ? team : adminLogin,
    layout: hasSubdomain ? "admin-layout" : "account-layout",
  },
  {
    layout: "account-layout",
    name: "admin-register",
    path: "/admin/register",
    component: register,
  },
  {
    layout: "account-layout",
    name: "admin-register-hotel",
    path: "/admin/registerHotel",
    component: registerHotel,
  },
  {
    layout: "",
    name: "terms-of-use",
    path: "/terms/termsOfUse",
    component: termsOfUse,
  },
  {
    layout: "",
    name: "privacy",
    path: "/terms/privacy",
    component: privateRumy,
  },
];

export default routes;
