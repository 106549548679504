



































import { Auth, RegisterModel } from "@/http/api/auth";
import { AntdValidateStatus } from "@/interfaces/Antd";
import { CodeModel, RequestCodeType } from "@/interfaces/Auth";
import { Vue, Component, Prop } from "vue-property-decorator";

const phoneRegex = /^[0-9]{9,}$/;
const codeRegex = /^[0-9]{6}$/;

@Component
export default class VerifyAdminPhone extends Vue {
  @Prop({ default: () => ({}) }) value: any;

  sendCodeLoading = false;
  submitLoading = false;
  sendCodeResult: any = null;

  get canSubmit() {
    return !!this.value.code;
  }

  get canVerify() {
    return !!this.sendCodeResult;
  }

  get canSendCode() {
    return !!this.value.phone !== this.canVerify;
  }

  get rules() {
    return {
      phone: [
        {
          trigger: "change",
          validator: async (rule: any, value: any, callback: any) => {
            try {
              if (!value) {
                callback(new Error("휴대폰 번호를 입력해주세요."));
                return;
              }

              if (!phoneRegex.test(value.replace(/-/g, ""))) {
                callback(new Error("올바른 번호를 입력해주시기 바랍니다."));
                return;
              }

              callback();
            } catch (ex) {
              callback(new Error("올바른 번호를 입력해주시기 바랍니다."));
            }
          },
        },
      ],
      ...(this.canVerify
        ? {
            code: [
              {
                trigger: "change",
                validator: async (rule: any, value: any, callback: any) => {
                  try {
                    if (!value) {
                      callback(new Error("인증번호를 입력해주세요."));
                    }

                    if (!codeRegex.test(value)) {
                      callback(new Error("올바른 번호를 입력해주시기 바랍니다."));
                    }

                    callback();
                  } catch (ex) {
                    callback(new Error("올바른 번호를 입력해주시기 바랍니다."));
                  }
                },
              },
            ],
          }
        : {}),
    };
  }

  sendCode() {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      try {
        this.sendCodeLoading = true;

        const data = {
          codeType: RequestCodeType.Register,
          phone: this.value.phone,
        };

        this.sendCodeResult = await Auth.sendCode(data);

        this.$message.success("인증번호가 발송되었습니다.");
      } catch (err) {
        const message = err?.response?.data?.message || "인증번호 발송에 실패하였습니다.";
        this.$message.error(message);
      } finally {
        this.sendCodeLoading = false;
      }
    });
  }

  submit() {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      try {
        this.submitLoading = true;

        await Auth.register({ ...this.value, objectId: this.sendCodeResult });
        this.$router.push({ name: "admin-root" });

        this.$message.success("회원가입이 완료되었습니다.");
      } catch (err) {
        this.$message.error(err.message);
      } finally {
        this.submitLoading = false;
      }
    });
  }
}
