import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Member } from "@/http/api/member";
import moment from "moment";
import { Hotel } from "@/http/api/hotel";
import { hexToRGB } from "@/utiles/hexToRGB";

@Module({
  namespaced: true,
})
export default class TeamModule extends VuexModule {
  memberDetail: any = {};
  memberList: any[] = [];
  noTeamList: any[] = [];
  teamDetail: any = {};
  teamList: any[] = [];
  groupedTeamList: any[] = [];

  colorList: any[] = ["#00fffb", "#ffe900", "#ff005a", "#00cbff", "#b2e3f9", "#fff09d", "#ffb6c4"];

  @Mutation
  setMemberDetail(value: any) {
    this.memberDetail = {
      ...this.memberDetail,
      ...value,
    };
  }

  @Mutation
  setTeamDetail(value: any) {
    this.teamDetail = {
      ...this.teamDetail,
      ...value,
    };
  }

  @Mutation
  resetMemberDetail(data: any) {
    if (!data || !data.objectId) {
      this.memberDetail = {};
    } else {
      this.memberDetail = {
        objectId: data.objectId,
        name: data.name,
        team: data.team,
      };
    }
  }

  @Mutation
  resetTeamDetail(data: any) {
    if (!data || !data.objectId) {
      this.teamDetail = {
        color: "#00fffb",
      };
    } else {
      this.teamDetail = {
        objectId: data.objectId,
        name: data.name,
        color: data.color,
      };
    }
  }

  @Mutation
  setNoTeamList(value: any[]) {
    this.noTeamList = value;
  }

  @Mutation
  setMemberList(value: any[]) {
    this.memberList = value;
  }

  @Mutation
  setTeamList(value: any[]) {
    this.teamList = value;
  }

  @Mutation
  setGroupedTeamList(value: any[]) {
    this.groupedTeamList = value;
  }

  @Action
  async loadMemberListAsync() {
    const teamList = await Hotel.teamListAsync();
    const memberList = await Member.list();

    // const filter = memberList.filter((item: any) => !item.team);
    // this.context.commit("setNoTeamList", filter);
    // this.context.commit("setMemberList", memberList);
    const groupedTeamList = groupedMemberListReducer(
      teamList,
      memberList.filter((member: any) => member.permission !== 3),
    );

    this.context.commit("setGroupedTeamList", groupedTeamList);
  }

  @Action
  async loadTeamListAsync() {
    const teamList = await Hotel.teamListAsync();
    this.context.commit("setTeamList", teamList);
  }

  @Action
  async loadMemberSingleAsync(objectId: string) {
    const memberDetail = await Member.single(objectId);
    this.context.commit("setMemberDetail", memberDetail);
  }

  @Action
  async updateMemberAsync(member: any) {
    const memberDetail = await Member.update(member);
    this.context.commit("setMemberDetail", memberDetail);
  }

  @Action
  async deleteTeamAsync(objectId: string) {
    await Hotel.deleteTeamAsync(objectId);
  }

  @Action
  async createTeam(teamDetail: any) {
    if (!teamDetail.objectId) {
      const team = await Hotel.createTeamAsync(teamDetail);
    } else {
      const updateTeam = await Hotel.updateTeamAsync(teamDetail);
    }
  }
}

const groupedMemberListReducer = (teamList: any[], memberList: any[]) => {
  return [{ name: "미분류", objectId: undefined }, ...teamList].map(team => {
    const members = memberList.filter(member => member.teamObjectId === team.objectId);

    const undefine = memberList.filter(member => !member.teamObjectId);

    return {
      key: team.objectId,
      team,
      members: team.objectId ? members : undefine,
    };
  });
};
