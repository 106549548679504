

































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import xlsx from "xlsx";
import moment from "moment";
import adminOrganizationTree from "@/components/admin/adminOrganizationTree.vue";
import { Member } from "@/http/api/member";

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");
const Modal = namespace("modal");

const permissions = ["사용자", "사용자", "관리자", "마스터"];

@Component({
  components: {
    adminOrganizationTree,
  },
})
export default class User extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @Modal.Action("showModal") showModal: any;
  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  get rules() {
    return {
      password: [
        {
          required: true,
          message: "비밀번호를 입력해주세요.",
        },
      ],
      confirm: [
        {
          required: true,
          message: "비밀번호를 한 번 더 입력해주세요.",
        },
      ],
    };
  }

  batchCreateData: any = null;
  files: any[] = [];

  moment: any = moment;

  change: any = {
    objectId: "",
    state: "",
    name: "",
  };

  changePassword: any = null;

  batchCreate: boolean = false;
  invite: boolean = false;

  activeKey: any = "";
  tabs: any = [];

  columns = [
    {
      title: "소속부서",
      dataIndex: "team",
      sorter: (a: any, b: any) => {
        if (!a.team) {
          return 1;
        }
        if (!b.team) {
          return -1;
        }
        return a.team > b.team ? 1 : -1;
      },
    },
    {
      title: "아이디",
      dataIndex: "id",
      scopedSlots: { customRender: "id" },
      sorter: (a: any, b: any) => (a.id > b.id ? 1 : -1),
    },
    {
      title: "이름",
      dataIndex: "name",
      sorter: (a: any, b: any) => (a.name > b.name ? 1 : -1),
    },
    {
      title: "권한",
      dataIndex: "permission",
      customRender: (value: any) => value && permissions[value],
      sorter: (a: any, b: any) => (a.permission > b.permission ? 1 : -1),
    },
    {
      title: "직급",
      dataIndex: "rank",
      sorter: (a: any, b: any) => {
        if (!a.rank) {
          return 1;
        }
        if (!b.rank) {
          return -1;
        }
        return a.rank > b.rank ? 1 : -1;
      },
    },
    {
      align: "right",
      title: "내선번호",
      dataIndex: "extension",
      sorter: (a: any, b: any) => {
        if (!a.extension) {
          return 1;
        }
        if (!b.extension) {
          return -1;
        }
        return a.extension > b.extension ? 1 : -1;
      },
    },
    {
      align: "right",
      title: "전화번호",
      dataIndex: "phone",
      sorter: (a: any, b: any) => {
        if (!a.phone) {
          return 1;
        }
        if (!b.phone) {
          return -1;
        }
        return a.phone > b.phone ? 1 : -1;
      },
    },
    {
      align: "right",
      title: "가입일",
      dataIndex: "createdTime",
      customRender: (value: any) => value && moment(value).format("yyyy/MM/DD"),
      sorter: (a: any, b: any) => (a.createdTime > b.createdTime ? 1 : -1),
    },
    {
      align: "right",
      title: "마지막 접속시간",
      dataIndex: "lastChallengeTime",
      customRender: (value: any) => value && moment(value).format("yyyy/MM/DD HH:mm"),
      sorter: (a: any, b: any) => {
        if (!a.lastChallengeTime) {
          return 1;
        }
        if (!b.lastChallengeTime) {
          return -1;
        }
        return a.lastChallengeTime > b.lastChallengeTime ? 1 : -1;
      },
    },
    {
      align: "right",
      title: "수정시간",
      dataIndex: "updatedTime",
      customRender: (value: any) => value && moment(value).format("yyyy/MM/DD HH:mm"),
      sorter: (a: any, b: any) => (a.updatedTime > b.updatedTime ? 1 : -1),
    },
    {
      align: "right",
      title: "비밀번호 변경",
      scopedSlots: { customRender: "password" },
    },
    {
      width: 120,
      align: "right",
      title: "상태",
      dataIndex: "state",
      scopedSlots: { customRender: "state" },
    },
  ];

  inviteColumns = [
    {
      title: "소속부서",
      dataIndex: "team",
    },
    {
      title: "아이디",
      dataIndex: "id",
      scopedSlots: { customRender: "id" },
    },
    {
      title: "이름",
      dataIndex: "name",
    },
    {
      title: "직급",
      dataIndex: "rank",
    },
    {
      align: "right",
      title: "내선번호",
      dataIndex: "extension",
    },
    {
      align: "right",
      title: "전화번호",
      dataIndex: "phone",
    },
    {
      align: "right",
      title: "가입일",
      dataIndex: "createdTime",
      customRender: (value: any) => value && moment(value).format("yyyy/MM/DD"),
    },
    {
      align: "center",
      title: "초대",
      scopedSlots: { customRender: "invite" },
    },
  ];

  get permission() {
    if (this.hotelInfo.userPermission == 2) {
      return false;
    }
    return true;
  }

  rowPermission(objectId: string) {
    if (
      this.hotelInfo.userPermission == 3 ||
      (this.hotelInfo.userPermission == 2 && objectId == this.hotelInfo.teamObjectId)
    ) {
      return true;
    }
    return false;
  }

  mounted() {
    this.loadMemberListAsync();
  }

  async stateChange(item: any, options: any) {
    const objectId = options.data.attrs.objectId;
    this.change = {
      state: item,
      objectId,
      name: item == "disabled" ? "사용중지" : "사용",
    };
  }

  async stateConfirm() {
    try {
      await Member.state(this.change.objectId, this.change.state);
      this.change = {};
      await this.loadMemberListAsync();
      this.$message.success("유저 상태가 변경 되었습니다.");
    } catch {
      this.$message.error("유저 상태 변경에 실패하였습니다.");
    }
  }

  stateCancel() {
    this.change = {};
  }

  openChangePassword(item: any) {
    const { objectId } = item;
    this.changePassword = { objectId };
  }

  closeChangePassword() {
    this.changePassword = null;
  }

  async applyChangePassword() {
    const form: any = this.$refs.form;
    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      if (!this.changePassword.password) {
        this.$message.warning("비밀번호를 입력해주세요.");
        return;
      }

      if (!this.changePassword.confirm) {
        this.$message.warning("비밀번호를 한 번 더 입력해주세요.");
        return;
      }

      if (this.changePassword.password != this.changePassword.confirm) {
        this.$message.warning("입력하신 서로 비밀번호가 다릅니다.");
        return;
      }

      try {
        await Member.password(this.changePassword.objectId, this.changePassword.password);
        this.$message.success("비밀번호가 변경되었습니다.");
        this.closeChangePassword();
      } catch {
        this.$message.error("비밀번호 변경에 실패하였습니다.");
      }
    });
  }

  openBatchCreate() {
    this.batchCreate = true;
  }

  closeBatchCreate() {
    this.batchCreate = false;
    this.batchCreateData = null;
    this.files = [];
  }

  async applyBatchCreate() {
    try {
      if (!this.batchCreateData || this.batchCreateData.lenght === 0) {
        this.$message.warning("일괄등록 할 데이터가 없습니다.");
        return;
      }

      await Member.batchCreate(this.batchCreateData);

      this.$message.success("일괄등록 되었습니다.");
      this.loadMemberListAsync();
      this.closeBatchCreate();
    } catch (ex) {
      if (ex.response) {
        const { data } = ex.response;
        const message = data && (data.error || data.Error);
        if (message && message.length < 100) {
          this.$message.error(`일괄등록에 실패하였습니다. ${message}`);
          return;
        }
      }

      this.$message.error(`일괄등록에 실패하였습니다.`);
    }
  }

  async fileUpload({ file, onSuccess, onError }: any) {
    try {
      const data = await this.readData(file);

      onSuccess({
        data: data,
        fileName: file.name,
        size: file.size,
      });
    } catch (ex) {
      onError(ex);
    }
  }

  changeFile(event: any) {
    const { status, response } = event.file;

    switch (status) {
      case "uploading":
        break;

      case "done":
        this.$message.success("파일 업로드에 성공하였습니다.");
        this.batchCreateData = response.data;
        this.files = [response];
        break;

      case "error":
        this.$message.error("파일 업로드에 실패하였습니다.");
        break;
    }
  }

  async readData(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const workbook = xlsx.read(reader.result, { type: "binary" });
        const name = workbook.SheetNames[0];
        const result = xlsx.utils.sheet_to_json(workbook.Sheets[name]);

        resolve(
          result.map((item: any) => ({
            name: item.이름?.toString(),
            id: item.아이디?.toString(),
            password: item.비밀번호?.toString(),
            rank: item.직급?.toString(),
            extension: item.내선번호?.toString(),
            phone: item.전화번호?.toString(),
            email: item.이메일?.toString(),
            team: item.소속부서?.toString(),
          })),
        );
      };

      reader.onerror = (ex) => {
        reject(ex);
      };

      reader.readAsBinaryString(file);
    });
  }

  downloadUsers() {
    try {
      const data: any = this.memberList.map((item: any) => ({
        이름: item.name,
        아이디: item.id,
        비밀번호: item.password,
        직급: item.rank,
        내선번호: item.extension,
        전화번호: item.phone,
        이메일: item.email,
        소속부서: item.team,
      }));

      const workbook = xlsx.utils.book_new();
      const sheet = xlsx.utils.json_to_sheet(data);

      xlsx.utils.book_append_sheet(workbook, sheet);

      const name = this.hotelInfo.name;

      xlsx.writeFile(
        workbook,
        `[${moment().format("YYYY-MM-DD HH:mm")}] RUMY ${name} 유저목록.xlsx`,
      );
    } catch (ex) {
      this.$message.error("양식 다운로드에 실패하였습니다.");
    }
  }

  downloadTemplate() {
    try {
      const data: any = [
        {
          이름: "",
          아이디: "",
          비밀번호: "",
          직급: "",
          내선번호: "",
          전화번호: "",
          이메일: "",
          소속부서: "",
        },
      ];

      const workbook = xlsx.utils.book_new();
      const sheet = xlsx.utils.json_to_sheet(data);

      xlsx.utils.book_append_sheet(workbook, sheet);

      xlsx.writeFile(workbook, `RUMY 유저 일괄등록 양식.xlsx`);
    } catch (ex) {
      this.$message.error("양식 다운로드에 실패하였습니다.");
    }
  }

  async loadMembers() {
    const list = await Member.all();
    this.tabs = list.map((item: any) => ({
      closable: false,
      key: item.key,
      title: item.name,
      data: { ...item },
    }));
    this.activeKey = this.tabs[0].key;
  }

  async openInvite() {
    await this.loadMembers();
    this.invite = true;
  }

  closeInvite() {
    this.invite = false;
  }

  async inviteUser(tab: any, item: any) {
    try {
      await Member.invite(tab.key, item.objectId);

      this.$message.success("사용자를 초대했습니다.");
      this.loadMemberListAsync();
      this.closeBatchCreate();
    } catch (ex) {
      if (ex.response) {
        const { data } = ex.response;
        const message = data && (data.error || data.Error);
        if (message && message.length < 100) {
          this.$message.error(`초대에 실패하였습니다. ${message}`);
          return;
        }
      }

      this.$message.error("초대에 실패하였습니다.");
    }
  }

  inviteDisabled(item: any) {
    return (
      item.state !== "enabled" || this.memberList.some((_: any) => _.objectId === item.objectId)
    );
  }

  inviteButtonText(item: any) {
    if (item.state !== "enabled") {
      return "사용중지";
    }

    if (this.memberList.some((_: any) => _.objectId === item.objectId)) {
      return "초대됨";
    }

    return "초대";
  }
}
