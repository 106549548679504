










import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";
import { VueEditor, Quill } from "vue2-editor";
import "quill-mention";

const MemberModule = namespace("MemberModule");

@Component({
  components: {
    VueEditor,
  },
})
export default class IssueContents extends Vue {
  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  @Prop() placeholder?: string;

  get editor() {
    return this.$refs.editor;
  }

  contents = "";

  editorOptions: any = {
    modules: {
      toolbar: null,
      mention: {
        allowedChars: /^[가-힣A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        source: this.source,
      },
    },
    formats: ["mention"],
  };

  async source(searchTerm: string, renderList: Function) {
    searchTerm = searchTerm.toLowerCase();
    const list = this.memberList
      .filter((item: any) => item.name.toLowerCase().indexOf(searchTerm) != -1)
      .map((item: any) => ({ id: item.objectId, value: item.name }));

    renderList(list, searchTerm);
  }

  created() {
    this.loadMemberListAsync();
  }

  mounted() {
    const editor = this.$refs.editor as any;
    this.$emit("load", editor);
  }
}
