


























import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import moment from "moment";
import { tagStyle, tagText } from "@/constants";

const IssueModule = namespace("IssueModule");
const NoticeModule = namespace("NoticeModule");

@Component
export default class BookmarkCard extends Vue {
  @Prop() data: any;

  @IssueModule.Action("updateRead") issueUpdateRead: any;
  @NoticeModule.Action("updateRead") noticeUpdateRead: any;

  get fromNow() {
    return moment(this.data.targetCreatedTime).fromNow();
  }

  get profileStyle() {
    const url = this.data.writerProfile || "/assets/settings/default-user.svg";
    return {
      backgroundImage: `url('${url}')`,
    };
  }

  get tagStyle() {
    return tagStyle(this.data.targetType);
  }

  get tagText() {
    return tagText(this.data.targetType);
  }

  get writerName() {
    return this.data.writerName || "이름없음";
  }

  get writerTeam() {
    return this.data.writerTeam || "미소속";
  }

  get contents() {
    const result = [];

    if (this.data.category) {
      result.push(`[${this.data.category}]`);
    }

    if (this.data.contents) {
      result.push(this.data.contents);
    }

    return result.join(" ");
  }

  async click() {
    const objectId = this.data.targetObjectId;

    switch (this.data.targetType) {
      case "issue":
        await this.toIssue(objectId);
        break;

      case "notice":
        await this.toNotice(objectId);
        break;
    }
  }

  async toIssue(objectId: any) {
    try {
      await this.issueUpdateRead(objectId);

      this.$router.push({
        name: "issue-detail",
        params: {
          objectId,
        },
      });
    } catch (err) {
      this.$message.error("해당 이슈는 삭제되었습니다.");
      this.$router.push({ name: "issue" });
    }
  }

  async toNotice(objectId: any) {
    try {
      await this.noticeUpdateRead(objectId);

      this.$router.push({
        name: "notice-detail",
        params: {
          objectId,
        },
      });
    } catch (err) {
      this.$message.error("해당 게시글은 삭제되었습니다.");
      this.$router.push({ name: "notice" });
    }
  }
}
