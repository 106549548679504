import notice from "./notice.vue";
import noticeDetail from "./noticeDetail.vue";

export default [
  {
    name: "notice",
    path: "/notice",
    component: notice,
  },
  {
    name: "notice-detail",
    path: "/notice/:objectId",
    component: noticeDetail,
  },
];
