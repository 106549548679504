

























































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import { cloneDeep } from "lodash";
import { Hotel } from "@/http/api/hotel";

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");

@Component({
  components: {},
})
export default class AdminOrganizationTree extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadHotelAsync") loadHotelAsync: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;
  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;
  @MemberModule.Action("changeOrder") changeOrder: any;

  treeData: any[] = [];
  displayTreeData: any[] = [];
  keyword: string = "";
  selectedUser: string[] = [];
  expandedKeys: string[] = [];

  mounted() {
    this.load();
  }

  async onDrop(info: any) {
    const dropKey = info.node.eventKey;
    const dragKey = info.dragNode.eventKey;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const dragNodesKeys = info.dragNodesKeys;

    if (dropPosition == 0) {
      return;
    }

    if (dragNodesKeys.length > 1) {
      return;
    }

    try {
      await this.changeOrder({
        targetObjectId: dropKey,
        changedObjectId: dragKey,
        position: dropPosition,
      });

      this.$message.success("순서를 변경했습니다.");
    } catch (ex) {
      if (ex.response) {
        const { data } = ex.response;
        const message = data && (data.error || data.Error);
        if (message && message.length < 100) {
          this.$message.error(`순서 변경을 실패했습니다. ${message}`);
          return;
        }
      }

      this.$message.error("순서 변경을 실패했습니다.");
    }

    this.load();
  }

  get permission() {
    if (this.hotelInfo.userPermission === 3) {
      return true;
    }
    return false;
  }

  async load() {
    await this.loadHotelAsync();
    await this.loadInfoAsync();
    await this.loadMemberListAsync();

    const temas = [
      ...this.hotelInfo.teams.map((team: any) => ({
        key: team.objectId,
        title: team.name,
        scopedSlots: { title: "team" },
      })),
      {
        key: "no-team",
        title: "미소속",
        scopedSlots: { title: "team" },
      },
    ].map((team: any) => {
      const children = this.memberList
        .filter((item: any) =>
          team.key !== "no-team" ? item.teamObjectId === team.key : !item.teamObjectId,
        )
        .map((item: any) => ({
          key: item.objectId,
          title: item.name,
          profile: item.profile,
          parent: team.key,
          isLeaf: true,
          scopedSlots: { title: "member" },
        }));

      return { ...team, children };
    });

    this.treeData = [
      {
        title: this.hotelInfo.name,
        key: "hotel",
        children: temas,
      },
    ];

    this.expandedKeys = ["hotel", ...temas.map((item: any) => item.key)];
  }

  onExpand(expandedKeys: string[]) {
    this.expandedKeys = expandedKeys;
  }

  async deleteTeam(item: any) {
    try {
      await Hotel.deleteTeamAsync(item.key);
      this.$message.success("부서가 삭제되었습니다.");
      this.load();
    } catch {
      this.$message.error("부서 삭제에 실패하였습니다");
    }
  }

  async showEditTeam(item: any) {
    const team = this.hotelInfo.teams.find((a: any) => a.objectId === item.key);
    this.$emit("editTeam", team);
  }

  async showEditMember(item: any) {
    const team = this.hotelInfo.teams.find((a: any) => a.objectId === item.key);
    this.$emit("editMember", team);
  }

  getProfileStyle(item: any) {
    return item.profile
      ? { backgroundImage: `url('${item.profile}')` }
      : {
        backgroundImage: `url('/assets/components/default-profile.svg')`,
        backgroundSize: "50%",
      };
  }
}
