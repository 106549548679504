import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import { RootState } from "./modules/types";

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules,
});
