








































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Advertisement } from "@/http/api/advertisement";
import { fileUpload } from "@/constants";
import moment from "moment";

const maxFileSize = 1024 * 1024 * 20;

@Component({
  components: {},
})
export default class SupportAdvertisement extends Vue {
  advertisements: any = [];
  showModal: boolean = false;
  areas: any = [
    {
      name: "로그인",
      value: "login",
    },
    {
      name: "호텔",
      value: "hotel",
    },
  ];
  form: any = {
    name: "file",
    originalName: "",
    fileUrl: "",
    linkUrl: "",
    key: "login",
  };
  loading: boolean = true;
  uploading: boolean = false;
  selectedArea = this.areas.map((a: any) => a.value);

  clickLogColumns = [
    {
      title: "호텔",
      dataIndex: "hotel",
    },
    {
      title: "유저",
      dataIndex: "user",
    },
    {
      title: "시각",
      dataIndex: "createdTime",
      customRender: (value: any) => value && moment(value).format("yyyy/MM/DD hh:mm:ss")
    },
  ];

  mounted() {
    this.load();
  }

  async load() {
    this.loading = true;
    this.advertisements = await Advertisement.All();
    this.loading = false;
  }

  imageUrl(fileUrl: string) {
    if (fileUrl.startsWith("http")) {
      return fileUrl;
    } else return `https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/${fileUrl}`;
  }

  async fileUpload({ file, onSuccess, onError }: any) {
    try {
      const result = await fileUpload(file, maxFileSize);
      onSuccess(result);
    } catch (ex) {
      onError(ex);
    }
  }

  changeFile(event: any) {
    const { status, response, error } = event.file;

    switch (status) {
      case "uploading":
        this.uploading = true;
        break;

      case "done":
        this.$message.success("파일 업로드에 성공하였습니다.");
        this.form = {
          ...this.form,
          fileUrl: response.url,
          originalName: response.fileName,
        };
        this.uploading = false;
        break;

      case "error":
        switch (error.message) {
          case "Large Size":
            this.$message.error("파일 용량이 20MB를 초과하였습니다.");
            break;
          default:
            this.$message.error("파일 업로드에 실패하였습니다.");
            break;
        }

        break;
    }
  }

  changeArea(value: string) {
    this.form.key = value;
  }

  async ok() {
    try {
      if (!this.form.originalName || !this.form.fileUrl) {
        return this.$message.warning("이미지를 첨부해주세요.");
      }

      if (this.form.objectId) {
        await Advertisement.Update(this.form);
      } else {
        await Advertisement.Create(this.form);
      }
      this.cancel();
      this.load();
      this.$message.success("광고를 성공적으로 추가하였습니다.");
    } catch {
      this.$message.error("광고를 추가하지 못했습니다.");
    }
  }

  cancel() {
    this.form = {
      name: "file",
      originalName: "",
      fileUrl: "",
      linkUrl: "",
      key: "login",
    };
    this.showModal = false;
  }

  edit(adv: any) {
    this.form = {
      ...adv,
    };
    this.showModal = true;
  }

  async deleteAdv(id: any) {
    try {
      await Advertisement.Delete(id);
      this.cancel();
      this.load();
      this.$message.success("광고를 성공적으로 삭제하였습니다.");
    } catch {
      this.$message.error("광고를 삭제하지 못하였습니다.");
    }
  }
}
