

















import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import notificationTabCard from "@/components/layouts/notificationTabCard.vue";
import _ from "lodash";

const CollectionModule = namespace("CollectionModule");

@Component({
  components: {
    notificationTabCard,
  },
})
export default class NotificationTab extends Vue {
  @CollectionModule.State("notificationList") notificationList: any;
  @CollectionModule.Action("loadNotifications") loadNotifications: any;

  mounted() {
    this.load(true);
    this.addScrollEvent(this.scrollView);
  }

  get scrollView() {
    return this.$refs.scrollView as any;
  }

  addScrollEvent(scrollView: any) {
    scrollView.addEventListener("scroll", this.throttle);
  }

  destroyed() {
    this.destroyScrollEvent(this.scrollView);
  }

  destroyScrollEvent(scrollView: any) {
    scrollView.removeEventListener("scroll", this.throttle);
  }

  async load(clear: boolean) {
    this.loadNotifications(clear);
  }

  handleScroll() {
    const scrollTop = (this.scrollView && this.scrollView.scrollTop) || this.scrollView.scrollTop;
    const bottomOfWindow = scrollTop + this.scrollView.clientHeight;

    if (this.scrollView.scrollHeight - bottomOfWindow < 150) {
      this.load(false);
    }
  }

  throttle = _.throttle(this.handleScroll, 500);
}
