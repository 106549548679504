











































import { Vue, Component, Prop } from "vue-property-decorator";
import { AntdValidateStatus } from "@/interfaces/Antd";
import { Auth, RegisterModel } from "@/http/api/auth";

const nameRegex = /^.{2,20}$/;
const IdRegex = /^[a-zA-Z0-9가-힣_\-]{4,20}$/;
const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{6,20}$/;
const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  components: {},
})
export default class RegisteradminForm extends Vue {
  @Prop({ default: () => ({}) }) value: any;

  emailTrailingList = ["naver.com", "daum.net", "hanmail.net", "gmail.com"];

  get rules() {
    return {
      name: [
        {
          trigger: "blur",
          validator: (rule: any, value: any, callback: any) => {
            if (!value) {
              callback(new Error("이름을 입력해주세요."));
              return;
            }

            if (!nameRegex.test(value)) {
              callback(new Error("이름은 2글자 이상, 20자 이하 이어야 합니다."));
              return;
            }

            callback();
          },
        },
      ],
      id: [
        {
          trigger: "blur",
          validator: async (rule: any, value: any, callback: any) => {
            if (!value) {
              callback(new Error("이름을 입력해주세요."));
              return;
            }

            if (!IdRegex.test(value)) {
              callback(new Error("아이디는 공백, 특수문자 없이 4글자 이상이어야 합니다."));
              return;
            }

            try {
              await Auth.checkId(value);
            } catch {
              callback(new Error("이미 사용중인 아이디입니다."));
            }

            callback();
          },
        },
      ],
      password: [
        {
          trigger: "change",
          validator: async (rule: any, value: any, callback: any) => {
            try {
              if (!value) {
                callback(new Error("변경할 비밀번호를 입력해주세요."));
                return;
              }

              if (!passwordRegex.test(value)) {
                callback(
                  new Error("비밀번호는 문자, 숫자, 특수문자가 포함된 6글자 이상이어야 합니다."),
                );
                return;
              }

              callback();
            } catch (ex) {
              callback(
                new Error("비밀번호는 문자, 숫자, 특수문자가 포함된 6글자 이상이어야 합니다."),
              );
            }
          },
        },
      ],
      confirm: [
        {
          trigger: "change",
          validator: async (rule: any, value: any, callback: any) => {
            try {
              if (!value) {
                callback(new Error("비밀번호를 한 번 더 입력해주세요."));
                return;
              }

              if (this.value.password !== value) {
                callback(new Error("입력하신 비밀번호가 다릅니다."));
                return;
              }

              callback();
            } catch (ex) {
              callback(new Error("입력하신 비밀번호가 다릅니다."));
            }
          },
        },
      ],
      email: [
        {
          trigger: "change",
          validator: async (rule: any, value: any, callback: any) => {
            try {
              if (!value) {
                callback(new Error("이메일을 입력해주세요."));
                return;
              }

              if (!emailRegex.test(value)) {
                callback(new Error("올바르지 않은 이메일 양식입니다."));
                return;
              }

              callback();
            } catch (ex) {
              callback(new Error("올바르지 않은 이메일 양식입니다."));
            }
          },
        },
      ],
    };
  }

  changeEmailLeading(e: any) {
    this.value.email = `${e.target.value}@${this.value.emailTrailing}`;

    const email = this.$refs.email as any;
    email.onFieldChange();
  }

  changeEmailTrailing(value: any) {
    this.value.email = `${this.value.emailLeading}@${value}`;

    const email = this.$refs.email as any;
    email.onFieldChange();
  }

  submit() {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      this.$emit("next");
    });
    // const form: RegisterModel = {
    //   name: this.name,
    //   id: this.id,
    //   password: this.password,
    //   email: this.email,
    // };
    // const validateResult =
    //   this.validateName() &&
    //   (await this.validateID()) &&
    //   this.validatePassword() &&
    //   this.validateConfirmPassword() &&
    //   this.validateEmail();
    // if (!validateResult) {
    //   return;
    // }
    // this.$emit("next", form);
  }
}
