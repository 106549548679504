export interface SendCodeModel {
  target: string;
  codeType: RequestCodeType;
}

export enum RequestCodeType {
  Register = 0,
  FindId = 1,
  FindPassword = 2,
}

export interface CodeModel extends SendCodeModel {
  objectId: string;
  code?: string;
  message: string;
  createdTime: Date;
  verifySucceededTime?: Date;
  expiredTime: Date;
}

export interface FindIdModel {
  name: string;
  phone: string;
}

export interface FindPasswordModel extends FindIdModel {
  id: string;
}

export interface FindPasswordChangeModel {
  objectId: string;
  newPassword: string;
}
