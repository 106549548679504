import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Work } from "@/http/api/work";

@Module({
  namespaced: true,
})
export default class WorkModule extends VuexModule {
  work: any = {};

  @Mutation
  setWork(value: any) {
    this.work = { ...value };
  }

  @Action({ rawError: true })
  async loadWork() {
    const data = await Work.getWork();
    this.context.commit("setWork", data);
  }

  @Action
  async createWorkCategory(title: string) {
    const data = await Work.createWorkCategory({ title });
    this.context.commit("setWork", data);
  }

  @Action
  async updateWorkCategory(value: any) {
    const categoryId = value.categoryId;
    const currentCategoryIndex = value.currentCategoryIndex;
    const data = await Work.updateWorkCategory(categoryId, { currentCategoryIndex });
    this.context.commit("setWork", data);
  }

  @Action
  async updateWorkCategoryTitle(value: any) {
    const categoryId = value.categoryId;
    const title = value.title;
    const data = await Work.updateWorkCategoryTitle(categoryId, { title });
    this.context.commit("setWork", data);
  }

  @Action
  async updateWorkCategoryColor(categoryId: string, color: string) {
    const data = await Work.updateWorkCategoryColor(categoryId, { color });
    this.context.commit("setWork", data);
  }

  @Action
  async deleteWorkCategory(categoryId: string) {
    const data = await Work.deleteWorkCategory(categoryId);
    this.context.commit("setWork", data);
  }

  @Action
  async createWorkCard(value: any) {
    const categoryId = value.categoryId;
    const subject = value.subject;
    const data = await Work.createWorkCard(categoryId, { subject });
    this.context.commit("setWork", data);
  }

  @Action
  async updateWorkCard(value: any) {
    const categoryId = value.categoryId;
    const cardId = value.cardId;
    const currentCardIndex = value.currentCardIndex;
    const data = await Work.updateWorkCard(categoryId, cardId, { currentCardIndex });
    this.context.commit("setWork", data);
  }

  @Action
  async updateWorkCardSubject(value: any) {
    const categoryId = value.categoryId;
    const cardId = value.cardId;
    const subject = value.subject;
    const data = await Work.updateWorkCardSubject(categoryId, cardId, { subject });
    this.context.commit("setWork", data);
  }

  @Action
  async deleteWorkCard(value: any) {
    const categoryId = value.categoryId;
    const cardId = value.cardId;
    const data = await Work.deleteWorkCard(categoryId, cardId);
    this.context.commit("setWork", data);
  }
}
