

































































































































































































































import Vue from "vue";
import IssueContents from "@/components/issues/issueContents.vue";
import Component from "vue-class-component";
import { State, Action, namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";
import moment, { Moment } from "moment";
import { textToJson, jsonToText, htmlToText, jsonToHtml } from "@/utiles/contentsParser";
import { File } from "@/http/api/file";
import axios from "axios";
import { IFileData } from "../interfaces/File";
import organizationTree from "@/components/layouts/organizationTree.vue";
import { fileUpload, uploadIcon } from "@/constants";

const maxFileSize = 1024 * 1024 * 20;

const HotelModule = namespace("HotelModule");
const IssueModule = namespace("IssueModule");
const MemberModule = namespace("MemberModule");
const Modal = namespace("modal");

@Component({
  name: "writeIssueModal",
  components: {
    IssueContents,
    organizationTree,
  },
})
export default class WriteIssueModal extends Vue {
  @Prop() defaultData: any;
  @Prop({ default: false }) openReserved: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;
  @MemberModule.State("memberList") memberList: any;

  @IssueModule.Action("writeIssue") writeIssue: any;

  @Modal.State("modal") modal: any;
  @Modal.Action("handleOk") handleOk: any;
  @Modal.Action("handleCancel") handleCancel: any;

  options: any[] = [
    {
      key: "mention",
      name: "멤버 선택",
      images: {
        on: "/assets/components/mention-active.png",
        off: "/assets/components/mention.png",
      },
    },
    {
      key: "file",
      name: "첨부파일",
      images: {
        on: "/assets/components/file-active.svg",
        off: "/assets/components/file.png",
      },
    },
    {
      key: "reserve",
      name: "예약작성",
      images: {
        on: "/assets/components/reserve-active.png",
        off: "/assets/components/reserve.png",
      },
    },
    {
      key: "todo",
      name: "체크리스트",
      images: {
        on: "/assets/components/issue-checklist-active.svg",
        off: "/assets/components/checklist-off.svg",
      },
    },
  ];

  loading: boolean = false;
  selectedOptionKey: string = "";
  timePickerFormat = "HH:mm";

  reserve: any = {
    isAllDay: false,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  };

  files: IFileData[] = [];
  mentions: any[] = [];

  todo: string = "";
  todos: any[] = [];

  moment = moment;
  uploadIcon = uploadIcon;

  data: any = {};

  get isSelectedAllTeam() {
    const { teamObjectIds } = this.data;
    const { teams } = this.hotelInfo;
    return teamObjectIds && teams && teamObjectIds.length === teams.length;
  }

  set isSelectedAllTeam(value: boolean) {
    if (value) {
      const teamObjectIds = this.hotelInfo.teams.map((item: any) => item.objectId);
      this.data = {
        ...this.data,
        teamObjectIds,
      };
    } else {
      this.data = {
        ...this.data,
        teamObjectIds: [],
      };
    }
  }

  get selectedOption() {
    return (
      this.selectedOptionKey &&
      this.options.find((item: any) => this.selectedOptionKey === item.key)
    );
  }

  get optionMentions() {
    const result: any[] = [];

    if (this.mentions.length) {
      result.push({
        key: "mention",
        icon: "/assets/components/tag-mention.svg",
        label: `멘션(${this.mentions.length}명)`,
        style: {
          border: "solid 1px #8270ff",
          backgroundColor: "rgba(130, 112, 255, 0.2)",
          color: "#8270ff",
        },
      });
    }

    if (this.files.length) {
      result.push({
        key: "file",
        icon: "/assets/components/tag-file.svg",
        label: `첨부파일(${this.files.length}개)`,
        style: {
          border: "solid 1px #87e8de",
          backgroundColor: "#e6fffb",
          color: "#87e8de",
        },
      });
    }

    if (this.reserve.startDate) {
      const value = moment(this.reserve.startDate).format("YYYY-MM-DD");
      result.push({
        key: "reserve",
        icon: "/assets/components/reserve.svg",
        label: `예약이슈(${value})`,
        style: {
          border: "solid 1px #ffbb96",
          backgroundColor: "#fff2e8",
          color: "#ff7a45",
        },
      });
    }

    if (this.todos.length) {
      result.push({
        key: "todo",
        icon: "/assets/components/tag-todo.svg",
        label: `체크리스트(${this.todos.length}개)`,
        style: {
          border: "solid 1px #91d5ff",
          backgroundColor: "#e6f7ff",
          color: "#40a9ff",
        },
      });
    }

    return result;
  }

  get disabledTeams() {
    return this.data.teamObjectIds
      ? this.hotelInfo.teams.length === this.data.teamObjectIds.length
      : false;
  }

  mounted() {
    this.data = {
      teamObjectIds: this.hotelInfo?.teamObjectId ? [this.hotelInfo.teamObjectId] : [],
    };

    if (this.defaultData) {
      const {
        reservedStartDate,
        reservedStartTime,
        reservedEndDate,
        reservedEndTime,
        mentions,
        files,
        todos,
        teams,
        isAllDay,
        ...defaultData
      } = this.defaultData;

      this.data = {
        ...defaultData,
        teamObjectIds: teams ? teams.map((item: any) => item.objectId) : this.data.teamObjectIds,
      };

      this.reserve = {
        isAllDay,
        startDate: reservedStartDate != null ? moment(reservedStartDate) : null,
        startTime:
          reservedStartTime != null && reservedStartTime.length !== 0
            ? moment(reservedStartTime, "HH:mm")
            : null,
        endDate: reservedEndDate != null ? moment(reservedEndDate) : null,
        endTime:
          reservedEndTime != null && reservedEndTime.length !== 0
            ? moment(reservedEndTime, "HH:mm")
            : null,
      };

      this.mentions = mentions ? mentions : [];
      this.files = files ? files : [];
      this.todos = todos ? [...todos] : [];

      if (this.openReserved) {
        this.selectedOptionKey = "reserve";
      }
    }

    this.modal.onCancel = () => {
      this.$confirm({
        title: "이슈 작성창을 닫으시겠습니까?",
        content: "작성하시던 이슈 내용은 삭제됩니다.",
        okText: "작성창 닫기",
        cancelText: "계속작성",
        onOk: () => {
          this.close();
        },
        onCancel: () => {},
      });
    };
  }

  selectOption(item: any) {
    this.selectedOptionKey = item.key;
  }

  optionStyle(item: any) {
    const selected = this.selectedOptionKey === item.key;

    return {
      backgroundColor: selected ? "#e9f1fd" : "white",
      backgroundImage: `url('${selected ? item.images.on : item.images.off}')`,
    };
  }

  async fileUpload({ file, onSuccess, onError }: any) {
    try {
      const result = await fileUpload(file, maxFileSize);
      onSuccess(result);
    } catch (ex) {
      onError(ex);
    }
  }

  changeFile(event: any) {
    const { status, response, error } = event.file;

    switch (status) {
      case "uploading":
        break;

      case "done":
        this.$message.success("파일 업로드에 성공하였습니다.");
        this.files = [...this.files, response];
        break;

      case "error":
        switch (error.message) {
          case "Large Size":
            this.$message.error("파일 용량이 20MB를 초과하였습니다.");
            break;
          default:
            this.$message.error("파일 업로드에 실패하였습니다.");
            break;
        }

        break;
    }
  }

  deleteFile(index: number) {
    this.files = this.files.filter((file: any, i: number) => index != i);
    this.$message.info("파일을 삭제하였습니다.");
  }

  addCheckList() {
    if (!this.todo) {
      return this.$message.warning("내용을 입력하세요.");
    }
    this.todos.push({ contents: this.todo });
    this.todo = "";
  }

  async editorLoad(editor: any) {
    if (this.defaultData) {
      const { contents } = this.defaultData;
      if (contents) {
        editor.quill.setText(contents);
      }
    }
  }

  startDisabledDate(current: Moment) {
    return current && current < moment().endOf("day");
  }

  endDisabledDate(current: Moment) {
    const date = this.reserve.startDate || moment().endOf("day");
    return current && current < date;
  }

  getContents() {
    const editor = this.$refs.editor as any;
    if (!(editor && editor.contents)) {
      return "";
    }

    const dom = new DOMParser().parseFromString(editor.contents, "text/html");
    return htmlToText(dom.body);
  }

  async submit() {
    if (!this.data.category) {
      this.$message.warning("카테고리를 선택해주세요.");
      return;
    }

    const contents = this.getContents();
    if (!contents) {
      this.$message.warning("내용을 입력해주세요.");
      return;
    }

    if (
      (this.reserve.startTime ||
        this.reserve.endDate ||
        this.reserve.endTime ||
        this.data.reservedPlace) &&
      !this.reserve.startDate
    ) {
      this.$message.warning("시작일을 입력해주세요.");
      return;
    }

    if (this.reserve.endTime && !this.reserve.endDate) {
      this.$message.warning("종료일을 입력해주세요.");
      return;
    }

    const details = {
      contents,
      reservedStartDate: this.reserve.startDate,
      reservedEndDate: this.reserve.endDate,
      reservedStartTime: this.reserve.startTime?.format("HH:mm:00"),
      reservedEndTime: this.reserve.endTime?.format("HH:mm:00"),
      files: this.files,
      todos: this.todos,
      mentions: this.mentions,
      isAllDay: this.reserve.isAllDay,
    };

    try {
      this.loading = true;

      await this.writeIssue({
        ...this.data,
        ...details,
      });

      this.$message.success(`이슈 ${this.data.objectId ? "수정" : "등록"}에 성공하였습니다.`);
      this.handleOk();
    } catch (e) {
      this.$message.error(`이슈 ${this.data.objectId ? "수정" : "등록"}에 실패하였습니다.`);
    } finally {
      this.loading = false;
    }
  }

  filterOption(input: any, option: any) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  close() {
    this.handleCancel({ forceClose: true });
  }

  closeOption() {
    this.selectedOptionKey = "";
  }
}

Vue.component("WriteIssueModal", WriteIssueModal);
