












































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import moment from "moment";
import adminOrganizationTree from "@/components/admin/adminOrganizationTree.vue";
import { Auth } from "@/http/api/auth";
import { Member } from "@/http/api/member";

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");
const Modal = namespace("modal");

@Component({
  components: {
    adminOrganizationTree,
  },
})
export default class UserWrite extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @Modal.Action("showModal") showModal: any;
  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  permissions = [
    { index: 1, label: "사용자" },
    { index: 2, label: "관리자" },
    { index: 3, label: "마스터" },
  ];

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loading = false;

  data: any = {
    permission: 1,
  };

  origin: any = {
    permission: 1,
  };

  _teamObjectIds: any = [];

  get teamObjectIds() {
    return [...(this.data.teamObjectIds || []), this.data.teamObjectId]
      .filter((item: any) => !!item)
      .reduce(
        (result: any, item: any) => (result.indexOf(item) !== -1 ? result : [...result, item]),
        [],
      );
  }

  set teamObjectIds(value: any) {
    this.data = {
      ...this.data,
      teamObjectIds: [...value]
    }
  }

  get id() {
    return this.$route.params?.id;
  }

  get edit() {
    return !!this.id;
  }

  get rules() {
    return {
      id: [
        {
          required: true,
          message: "아이디를 입력해주세요.",
        },
        {
          trigger: "blur",
          validator: async (rule: any, value: any, callback: any) => {

            if(value.length < 5 && !this.data.objectId) {
              callback(new Error("아이디는 5글자 이상이어야 합니다."));
            }
            
            try {
              if (value !== this.origin.id) {
                await Auth.checkId(value);
              }
              callback();
            } catch {
              callback(new Error("이미 사용중인 아이디입니다."));
            }
          },
        },
      ],
      name: [
        {
          required: true,
          message: "이름을 입력해주세요.",
        },
        {
          trigger: "blur",
          validator: async (rule: any, value: any, callback: any) => {
            try {
              if (value !== this.origin.name) {
                await Member.checkUserName(value);
              }
              callback();
            } catch {
              callback(new Error("이미 사용중인 이름입니다."));
            }
          },
        },
      ],
      password: [
        {
          required: !this.edit,
          message: "비밀번호를 입력해주세요.",
        },
      ],
      confirm: [
        {
          required: !this.edit,
          message: "비밀번호를 한 번 더 입력해주세요.",
        },
      ],
      permission: [
        {
          required: true,
          message: "권한을 입력해주세요.",
        },
      ],
    };
  }

  get isDisabledMainTeam() {
    if (this.hotelInfo.userPermission == 2) {
      if (this.data.teamObjectId && this.data.teamObjectId != this.hotelInfo.teamObjectId) {
        return true;
      }
    }
    return false;
  }

  isDisabledMainTeamOption(objectId: string) {
    if (this.hotelInfo.userPermission == 3) {
      return false;
    }
    if (this.hotelInfo.userPermission == 2) {
      if (this.hotelInfo.teamObjectId == objectId) {
        return false;
      }
    }
    return true;
  }

  isDisabledTeamOption(objectId: string) {
    if (this.hotelInfo.userPermission == 3) {
      return false;
    }
    if (objectId === this.data.teamObjectId) {
      return true;
    }
    if (this.hotelInfo.userPermission == 2) {
      if (this.hotelInfo.teamObjectId == objectId) {
        return false;
      }
    }
    return true;
  }

  async mounted() {
    await this.loadMemberListAsync();

    if (this.edit) {
      const member = this.memberList?.find((item: any) => item.objectId == this.id);
      if (!member) {
        this.$message.error("유저를 찾을 수 없습니다.");
        this.$router.replace({ name: "admin-user" });
        return;
      }

      this.origin = { ...member };
      this.data = { ...member };
    } else {
      this.data = {
        permission: 1,
      };
    }
  }

  submit() {
    const form: any = this.$refs.form;
    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      if (this.data.password != this.data.confirm) {
        return this.$message.warning("입력하신 비밀번호가 서로 다릅니다.");
      }

      const title = this.edit ? "수정" : "생성";
      try {
        if (this.edit) {
          await Member.update({ ...this.data, teamObjectIds: this.teamObjectIds });
        } else {
          await Member.create({ ...this.data, teamObjectIds: this.teamObjectIds });
        }

        this.$message.success(`유저가 ${title} 되었습니다.`);
        this.$router.replace({ name: "admin-user" });
      } catch (ex) {
        if (ex?.response?.customRender === 409) {
          this.$message.error(`이미 사용중인 아이디 또는 이름입니다.`);
        } else {
          this.$message.error(`유저 ${title}에 실패하였습니다.`);
        }
      }
    });
  }

  async deleteMember() {
    try {
      await Member.delete(this.data);
      this.$message.success("유저가 삭제되었습니다.");
    } catch {
      this.$message.error("유저 삭제에 실패하였습니다.");
    }

    this.$router.replace({ name: "admin-user" });
  }
}
