import { VuexModule, Module, Mutation, Action, MutationAction } from "vuex-module-decorators";
import { Issue } from "@/http/api/issue";
import { ReservedIssue } from "@/http/api/reservedIssue";
import { Reply } from "@/http/api/reply";
import moment, { Moment } from "moment";

@Module({
  namespaced: true,
})
export default class IssueModule extends VuexModule<any> {
  issueMap: any = {};

  groupedIssueList: any[] = [];
  periodIssueList: any[] = [];

  reportIssueList: any[] = [];

  issueReplyMap: any = {};

  @Mutation
  removeGroupedIssueItem(value: string) {
    this.groupedIssueList = removeGroupedIssueItemReducer(this.groupedIssueList, value);
  }

  @MutationAction
  async loadIssueList(options: any) {
    const { filter = {}, clear = false } = options || {};
    const take = filter.take || 20;
    const skip = clear ? 0 : filter.skip + take;

    const issues = await Issue.list({
      ...filter,
      skip,
      take,
    });

    if (issues.length > 0) {
      filter.skip = skip;
    }

    return upsertIssues({
      issues,
      issueMap: clear ? {} : this.state.issueMap,
      groupedIssueList: clear ? [] : this.state.groupedIssueList,
      periodIssueList: clear ? [] : this.state.periodIssueList,
    });
  }

  @MutationAction
  async loadIssue(objectId: string) {
    try {
      const issue = await Issue.singleIssue(objectId);

      const willShow = !moment(issue.displayingCreatedTime).isAfter(
        moment(new Date()).endOf("day"),
      );

      return upsertIssues({
        issues: willShow ? [issue] : [],
        issueMap: this.state.issueMap,
        groupedIssueList: this.state.groupedIssueList,
        periodIssueList: this.state.periodIssueList,
      });
    } catch (ex) {
      return {};
    }
  }

  /**
   * @deprecated DON'T USE THIS FUCKING METHOD, WHAT A MESS
   */
  @MutationAction
  async loadReportIssueListAsync(filter: any) {
    const { startDate, endDate } = filter;

    const issueList = await Issue.list({
      ...filter,
      startDate: startDate && moment(startDate),
      endDate: endDate && moment(endDate),
    });

    const replyMap = await Promise.all(
      issueList
        .filter(issue => issue.replyCount > 0)
        .map(async issue => {
          const replyList = await Reply.replyList(issue.objectId);
          return { objectId: issue.objectId, replyList };
        }),
    );

    const reportIssueList: any[] = issueList.reduce((groups: any[], issue: any) => {
      const map = replyMap.find(item => item.objectId === issue.objectId);
      if (map) {
        groups = [...groups, issue, ...map.replyList];
      } else {
        groups = [...groups, issue];
      }
      return groups;
    }, []);

    return { reportIssueList };
  }

  @MutationAction
  async loadReportIssueListAsyncV2(filter: any) {
    const { startDate, endDate } = filter;

    const issueList = await Issue.list({
      ...filter,
      startDate: startDate && moment(startDate),
      endDate: endDate && moment(endDate),
    });

    return { reportIssueList: issueList };
  }

  @MutationAction
  async writeIssue(data: any) {
    data = {
      ...data,
    };

    const issue: any = data.objectId
      ? await Issue.updateIssue(data)
      : await Issue.createIssue(data);

    const willShow = !moment(issue.displayingCreatedTime).isAfter(moment(new Date()).endOf("day"));
    return upsertIssues({
      issues: willShow ? [issue] : [],
      issueMap: this.state.issueMap,
      groupedIssueList: this.state.groupedIssueList,
      periodIssueList: this.state.periodIssueList,
    });
  }

  @MutationAction
  async updateStatus({ objectId, status }: UpdateStatusModel) {
    const issue = await Issue.updateStatus(objectId, status);

    return upsertIssues({
      issues: [issue],
      issueMap: this.state.issueMap,
      groupedIssueList: this.state.groupedIssueList,
      periodIssueList: this.state.periodIssueList,
    });
  }

  @MutationAction
  async updateRead(objectId: string) {
    const issue = await Issue.readIssue(objectId);

    return upsertIssues({
      issues: [issue],
      issueMap: this.state.issueMap,
      groupedIssueList: this.state.groupedIssueList,
      periodIssueList: this.state.periodIssueList,
    });
  }

  @MutationAction
  async deleteIssue(objectId: string) {
    await Issue.deleteIssue(objectId);

    return deleteIssue({
      objectId,
      issueMap: this.state.issueMap,
      groupedIssueList: this.state.groupedIssueList,
    });
  }

  @MutationAction
  async deleteSingleAsync(objectId: string) {
    return deleteIssue({
      objectId,
      issueMap: this.state.issueMap,
      groupedIssueList: this.state.groupedIssueList,
    });
  }

  @MutationAction
  async loadReservePeriodList({
    start,
    end,
    teamObjectIds,
    categories,
    keyword,
    keywordType,
    isNew,
    status,
  }: any) {
    const issues = await ReservedIssue.reservePeriodList({
      startDate: moment(start).format("YYYY-MM-DD"),
      endDate: moment(end).format("YYYY-MM-DD"),
      teamObjectIds,
      categories,
      keyword,
      keywordType,
      isNew,
      status,
    });

    return upsertIssues({
      issues,
      issueMap: this.state.issueMap,
      groupedIssueList: this.state.groupedIssueList,
      periodIssueList: issues,
    });
  }

  @MutationAction
  async issueBookmark({ objectId, value }: any) {
    const issue = await Issue.bookmark(objectId, value);

    return upsertIssues({
      issues: [issue],
      issueMap: this.state.issueMap,
      groupedIssueList: this.state.groupedIssueList,
      periodIssueList: this.state.periodIssueList,
    });
  }

  @MutationAction
  async updateChecklist({ issueObjectId, objectId, contents, isDone }: any) {
    const issue = await Issue.updateChecklist(issueObjectId, objectId, contents, isDone);

    return upsertIssues({
      issues: [issue],
      issueMap: this.state.issueMap,
      groupedIssueList: this.state.groupedIssueList,
      periodIssueList: this.state.periodIssueList,
    });
  }

  @MutationAction
  async updateReservation({ objectId, reservedStartDate, reservedEndDate }: any) {
    const issue = await Issue.updateReservation({ objectId, reservedStartDate, reservedEndDate });

    return upsertIssues({
      issues: [issue],
      issueMap: this.state.issueMap,
      groupedIssueList: this.state.groupedIssueList,
      periodIssueList: this.state.periodIssueList,
    });
  }

  @MutationAction
  async loadIssueReplies(objectId: string) {
    const list = await Reply.replyList(objectId);
    const issueReplyMap = { ...this.state.issueReplyMap, [objectId]: list };

    return { issueReplyMap };
  }
}

const upsertIssues = ({
  issues,
  issueMap,
  groupedIssueList,
  periodIssueList,
}: {
  issues: any[];
  issueMap: any;
  groupedIssueList: any[];
  periodIssueList: any[];
}) => {
  issueMap = issues.reduce((result, item) => ({ ...result, [item.objectId]: item }), issueMap);
  groupedIssueList = groupedIssueListReducer(issues, groupedIssueList);
  periodIssueList = issues
    .filter(item => item.reservedStartDate)
    .reduce(
      (result, item) =>
        result.find(({ objectId }: any) => objectId === item.objectId) ? result : [...result, item],
      periodIssueList,
    );

  return { issueMap, groupedIssueList, periodIssueList };
};

const deleteIssue = ({
  objectId,
  issueMap,
  groupedIssueList,
}: {
  objectId: string;
  issueMap: any;
  groupedIssueList: any[];
}) => {
  delete issueMap[objectId];
  groupedIssueList = removeGroupedIssueItemReducer(groupedIssueList, objectId);

  return { issueMap, groupedIssueList };
};

const groupedIssueListReducer = (list: any[], origin: any) => {
  const now = moment();
  list = list.filter(item =>
    item.reservedStartDate ? moment(item.reservedStartDate).diff(now, "days") < 1 : true,
  );

  const grouped: any[] = list.reduce((groups: any[], issue: any) => {
    const displayingCreatedTime = moment(issue.displayingCreatedTime);
    const key = displayingCreatedTime.format("YYYY/MM/DD");
    let group = groups.find(g => g.key === key);

    if (!group) {
      group = {
        key,
        title: displayingCreatedTime.format("YYYY/MM/DD dddd"),
        list: [issue],
      };

      groups = [...groups, group];
    } else {
      group.list = [...group.list.filter((i: any) => i.objectId !== issue.objectId), issue];
    }

    return groups;
  }, origin);

  return grouped
    .map(group => {
      return {
        ...group,
        list: group.list.sort((a: any, b: any) =>
          a.displayingCreatedTime === b.displayingCreatedTime
            ? a.createdTime > b.createdTime
              ? -1
              : 1
            : a.displayingCreatedTime > b.displayingCreatedTime
            ? -1
            : 1,
        ),
      };
    })
    .sort((a, b) => (a.key > b.key ? -1 : 1));
};

const removeGroupedIssueItemReducer = (groups: any[], removeObjectId: string) => {
  return groups
    .map(group => {
      if (!group.list.find((item: any) => item.objectId === removeObjectId)) {
        return group;
      }

      return {
        ...group,
        list: group.list.filter((item: any) => item.objectId !== removeObjectId),
      };
    })
    .filter(group => group.list.length);
};

export interface UpdateBooleanStatusModel {
  objectId: string;
  value: boolean;
}

export interface UpdateStatusModel {
  objectId: string;
  status: any;
}
