import user from "./user.vue";
import write from "./write.vue";

export default [
  {
    name: "admin-user",
    path: "/admin/user",
    component: user,
  },
  {
    name: "admin-user-detail",
    path: "/admin/user/write/:id?",
    component: write,
  },
];
