
















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Mutation, Getter, namespace } from "vuex-class";

import supportMenu from "@/components/support/supportMenu.vue";
import { Auth } from "@/http/api/auth";
import { Support } from "@/http/api/support";

const HotelModule = namespace("HotelModule");

@Component({
  components: {
    supportMenu,
  },
})
export default class SupportLayout extends Vue {
  @HotelModule.State("hotelObjectId") hotelObjectId: any;
  @HotelModule.Action("setHotelObjectId") setHotelObjectId: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadHotelAsync") loadHotelAsync: any;

  @HotelModule.Action("toHotelUrl") toHotelUrl: any;

  hotels = [];

  async mounted() {
    this.load();
  }

  async load() {
    this.loadHotelAsync();
    this.hotels = await Support.simpleHotels();
  }

  async logout() {
    try {
      await Auth.logout();
    } finally {
      this.toHotelUrl({ path: "" });
    }
  }

  filterOption(input: any, option: any) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  change(value: any) {
    this.setHotelObjectId(value);
  }
}
