import { VuexModule, Module, Mutation, Action, MutationAction } from "vuex-module-decorators";

import { Auth } from "@/http/api/auth";
import { Hotel } from "@/http/api/hotel";

const urlRegex = /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/;
const defaultHotelForm = {
  name: "",
  url: "",
  zipCode: "",
  areaLocation: "",
  detailLocation: "",

  validateStatus: {
    name: "",
    url: "",
  },

  error: {
    name: "",
    url: "",
  },
};

export interface ToHotelParams {
  url?: string;
  path?: string;
}

@Module({
  namespaced: true,
})
export default class HotelModule extends VuexModule<any> {
  registerHotelForm: any = defaultHotelForm;

  hotelInfo: any = {};

  hotelList: any[] = [];

  categoryList: any[] = [];
  noticeCategoryList: any[] = [];

  hotelObjectId: any = "";

  @MutationAction
  async setHotelObjectId(hotelObjectId: any) {
    return { hotelObjectId };
  }

  @Mutation
  resetRegisterHotelForm(value: any) {
    if (value) {
      this.registerHotelForm = {
        ...defaultHotelForm,
        ...value,
      };
    } else {
      this.registerHotelForm = defaultHotelForm;
    }
  }

  @Mutation
  setCategoryList(value: any[]) {
    this.categoryList = value;
  }

  @Mutation
  setHotelList(value: any[]) {
    this.hotelList = value;
  }

  @Mutation
  setNoticeCategoryList(value: any[]) {
    this.noticeCategoryList = value;
  }

  @Action
  async loadHotelAsync() {
    await Auth.hasHotel();
  }

  @MutationAction
  async loadInfoAsync() {
    const hotelInfo = await Hotel.info();
    return { hotelInfo };
  }

  @MutationAction
  async loadHotelListAsync() {
    const hotelList = await Hotel.list();
    return { hotelList };
  }

  @Action
  async checkName() {
    const { name } = this.registerHotelForm;
    if (!(name && name.length >= 2)) {
      this.registerHotelForm.validateStatus.name = "warning";
      this.registerHotelForm.error.name = "호텔명을 2글자 이상 입력해주세요.";
    } else {
      this.registerHotelForm.validateStatus.name = "success";
      this.registerHotelForm.error.name = "";
    }
  }

  @Action
  async checkUrl() {
    const { url } = this.registerHotelForm;

    if (!(url && url.length >= 2)) {
      this.registerHotelForm.validateStatus.url = "warning";
      this.registerHotelForm.error.url = "호텔 접속주소를 2글자 이상 입력해주세요.";
      return;
    }

    if (!urlRegex.test(url)) {
      this.registerHotelForm.validateStatus.url = "warning";
      this.registerHotelForm.error.url = "호텔 접속주소에 사용 불가한 문자가 포함되어있습니다.";
      return;
    }

    this.registerHotelForm.validateStatus.url = "";
    this.registerHotelForm.error.url = "";

    try {
      this.registerHotelForm.validateStatus.url = "validating";
      await Auth.checkUrl(url);
      this.registerHotelForm.validateStatus.url = "success";
      this.registerHotelForm.error.url = "";
    } catch {
      this.registerHotelForm.validateStatus.url = "error";
      this.registerHotelForm.error.url = "이미 등록된 URL입니다.";
    }
  }

  @Action
  async registerHotelAsync(data: any) {
    const { name, url, zipCode, areaLocation, detailLocation, file } = data;

    await Hotel.registerHotel({
      name,
      url,
      zipCode,
      areaLocation,
      detailLocation,
      file,
    });
  }

  @Action
  async createCategoryAsync(data: any) {
    await Hotel.createCategoryAsync(data);
  }

  @Action
  async createNoticeCategoryAsync(data: any) {
    await Hotel.createNoticeCategoryAsync(data);
  }

  @Action
  toHotelUrl({ url, path }: ToHotelParams) {
    const { protocol, host } = window.location;
    const hasSubdomain = host.split(".").length > (host.search(".co.kr") !== -1 ? 3 : 2);
    const domain = hasSubdomain ? host.substring(window.location.host.indexOf(".") + 1) : host;
    const createdHost = url ? `${url}.${domain}` : domain;

    window.location.href = `${protocol}//${createdHost}/${path || ""}`;
  }
}
