









































































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import homeLayout from "@/pages/layout/homeLayout.vue";

import RawHotelModule from "@/store/modules/hotelModule";
import RawModalModule from "@/store/modules/modal";
import { File } from "@/http/api/file";
import axios from "axios";
import { Auth } from "@/http/api/auth";
import { Hotel } from "@/http/api/hotel";

const HotelModule = namespace("HotelModule");
const Modal = namespace("modal");

const maxFileSize = 1024 * 1024 * 20;

const urlRegex = /^[A-Za-z0-9](?:[A-Za-z0-9-]{1,61}[A-Za-z0-9])?$/;

@Component({
  components: {
    homeLayout,
  },
})
export default class RegisterHotel extends Vue {
  @HotelModule.Action("toHotelUrl") toHotelUrl: any;

  loading: boolean = false;
  logo: any = {};
  data: any = {};

  get rules() {
    return {
      name: [
        {
          required: true,
          message: "호텔명을 입력해주세요.",
        },
      ],
      url: [
        {
          trigger: "blur",
          validator: async (rule: any, value: any, callback: any) => {
            if (!value) {
              callback(new Error("접속주소를 입력해주세요."));
              return;
            }

            if (!urlRegex.test(value)) {
              callback(new Error("접속주소에는 영문, 숫자, 하이픈(-) 만 사용 가능합니다."));
              return;
            }

            try {
              await Auth.checkUrl(value);
            } catch {
              callback(new Error("이미 사용중인 접속주소 입니다."));
            }

            callback();
          },
        },
      ],
    };
  }

  async presignedRequest({
    file,
    onSuccess,
    onError,
  }: {
    file: any;
    onSuccess: any;
    onError: any;
  }) {
    try {
      if (file.size >= maxFileSize) {
        throw Error("Large Size");
      }
      const { presignedUrl, url } = await File.getPreSignedUrl(file.name);

      await axios.request({
        url: presignedUrl,
        method: "PUT",
        data: file,
        headers: { "Access-Control-Allow-Origin": "*" },
      });

      onSuccess({
        url,
        fileName: file.name,
        size: file.size,
      });
    } catch (err) {
      this.$message.error("파일의 용량이 20MB가 넘어 파일 업로드에 실패하였습니다.");
      onError(err);
    }
  }

  async handleFileChange(event: any) {
    switch (event.file.status) {
      case "uploading":
        break;

      case "done":
        {
          const { response } = event.file;
          this.logo = response;

          this.$message.success("파일 업로드에 성공하였습니다.");
        }
        break;

      case "error":
        this.$message.error("파일 업로드에 실패하였습니다.");
        break;
    }
  }

  changeUrl() {
    const url = this.$refs.url as any;
    url.onFieldBlur();
  }

  submit() {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      try {
        this.loading = true;

        this.data.file = this.logo;

        await Hotel.registerHotel(this.data);

        this.toHotelUrl({
          url: this.data.url,
          path: "admin/team",
        });
      } catch (err) {
        this.$message.error(err?.response?.data?.message || "호텔 등록에 실패하였습니다.");
        return;
      } finally {
        this.loading = false;
      }
    });
  }

  async logout() {
    try {
      await Auth.logout();
    } finally {
      this.toHotelUrl({});
    }
  }
}
