






import { Component, Prop, Vue } from "vue-property-decorator";
import { host as clientHost } from "@/http/client";

@Component({})
export default class Dev extends Vue {
  devPosition: boolean = true;
  host = clientHost;

  get isDev() {
    return process.env.NODE_ENV === "development";
  }

  get style() {
    return this.devPosition ? "top: 0;" : "bottom: 0;";
  }

  togglePosition() {
    this.devPosition = !this.devPosition;
  }
}
