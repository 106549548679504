




























import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";
import { Popup } from "@/http/api/popup";

const Modal = namespace("modal");

@Component({
  components: {},
})
export default class NoticeModal extends Vue {
  @Prop() data: any;
  popups: any = [];
  current = 0;

  @Modal.Action("handleCancel") handleCancel: any;

  mounted() {
    this.popups = this.data;
    this.current = 0;
  }

  prev() {
    (this.$refs.carousel as any).prev();
  }

  next() {
    (this.$refs.carousel as any).next();
  }

  beforeChange(_: any, i: any) {
    this.current = i;
  }
}
Vue.component("NoticeModal", NoticeModal);
