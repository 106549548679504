










































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class AccountLayout extends Vue {
  @Prop({ default: "" }) header: any;
  @Prop({ default: "" }) description: any;
  @Prop({ default: true }) showFooter: any;
}
