




















































































































































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";
import ReadCount from "@/components/issues/readCount.vue";
import IssueCardTeam from "@/components/issues/issueCardTeam.vue";
import IssueReply from "@/components/issues/issueReply.vue";
import IssueReplyInput from "@/components/issues/issueReplyInput.vue";
import IssueReadonlyContents from "@/components/issues/issueReadonlyContents.vue";
import fileList from "@/components/fileList.vue";
import checkList from "@/components/checkList.vue";
import { HotelMemberPermission } from "@/http/dto/member";
import { IssueDto, IssueDetailDto, IssueStatus, IssueAction } from "@/http/dto/issue";
import { ISSUE_DATE_TIME_FORMAT } from "@/utiles/constants";
import { textToJson } from "@/utiles/contentsParser";
import IssueV2ModuleClass from "@/store/modules/v2/issueV2Module";
import IssueReplyV2ModuleClass from "@/store/modules/v2/issueReplyV2Module";
import { Issue as LegacyIssueApi } from "@/http/api/issue";
import { Issue as IssueApi } from "@/http/api/v2/issue";

const HotelModule = namespace("HotelModule");
const IssueV2Module = namespace("IssueV2Module");
const IssueReplyV2Module = namespace("IssueReplyV2Module");
const Modal = namespace("modal");

const statuses = [
  {
    color: "#ff5371",
    text: "새이슈",
    image: require("../../../public/assets/components/icon-issue-new.svg"),
  },
  {
    color: "#ffaa00",
    text: "진행중",
    image: require("../../../public/assets/components/icon-issue-ing.svg"),
  },
  {
    color: "#0acf9d",
    text: "완료",
    image: require("../../../public/assets/components/icon-issue-end.svg"),
  },
  {
    color: "#4c4c4c",
    text: "보류",
    image: require("../../../public/assets/components/icon-issue-holding.svg"),
  },
] as const;

const actions = {
  [IssueAction.RESERVED]: "예약됨",
  [IssueAction.CREATED]: "등록됨",
  [IssueAction.UPDATED]: "수정됨",
} as const;

@Component({
  components: {
    ReadCount,
    IssueCardTeam,
    IssueReply,
    IssueReplyInput,
    IssueReadonlyContents,
    fileList,
    checkList,
  },
})
export default class IssueCard extends Vue {
  @Prop({ required: true })
  issue!: IssueDto;

  @HotelModule.State("hotelInfo")
  hotelInfo!: Record<string, unknown>;

  @IssueReplyV2Module.State("repliesMap")
  repliesMap!: Record<string, Record<string, unknown>[]>;

  @IssueReplyV2Module.Action("fetchIssueReply")
  fetchIssueReply!: IssueReplyV2ModuleClass["fetchIssueReply"];

  @IssueV2Module.Action("updateIssue")
  updateIssue!: IssueV2ModuleClass["updateIssue"];

  @IssueV2Module.Action("deleteIssue")
  deleteIssue!: IssueV2ModuleClass["deleteIssue"];

  @Modal.Action("showModal")
  showModal: any;

  loading = false;
  readLogLoading = false;
  open = false;
  shouldLoadDependencies = true;
  detail: IssueDetailDto | null = null;
  readLogs: Record<string, unknown>[] = [];

  get reserved() {
    return this.issue?.action === IssueAction.RESERVED;
  }

  get deletable() {
    const permission = this.hotelInfo.userPermission ?? (0 as HotelMemberPermission);

    if (this.issue?.writer?.objectId === this.hotelInfo.userObjectId) {
      return true;
    }

    switch (permission) {
      case HotelMemberPermission.OWNER:
      case HotelMemberPermission.MANAGER:
        return true;

      default:
        return false;
    }
  }

  get replies() {
    return this.repliesMap[this.issue?.objectId ?? ""] ?? [];
  }

  get content() {
    return textToJson([], this.issue?.content ?? "");
  }

  get date() {
    let value = this.issue?.updatedAt;

    if (this.issue?.action === IssueAction.RESERVED) {
      value = this.issue?.createdAt;
    }

    return `${moment(value).format(ISSUE_DATE_TIME_FORMAT)} (${
      actions[this.issue?.action ?? IssueAction.CREATED]
    })`;
  }

  get status() {
    if (this.issue == null || this.issue?.status == null) {
      return statuses[IssueStatus.REGISTERED];
    }

    return statuses[this.issue.status];
  }

  get reservedInfo() {
    if (this.detail == null) {
      return "";
    }

    const {
      reservedStartDate,
      reservedEndDate,
      reservedStartTime,
      reservedEndTime,
      reservedPlace,
    } = this.detail as IssueDetailDto;

    if (!reservedStartDate) {
      return "";
    }

    let result: any[] = [];

    const dateFormat = "YYYY-MM-DD";
    const timeFormat = "HH:mm";

    if (reservedStartDate) {
      result = [...result, moment(reservedStartDate).format(dateFormat)];
    }

    if (reservedStartTime) {
      result = [...result, moment(reservedStartTime, "HH:mm").format(timeFormat)];
    }

    if (reservedEndDate || reservedEndTime) {
      result = [...result, "~"];
    }

    if (reservedEndDate) {
      result = [...result, moment(reservedEndDate).format(dateFormat)];
    }

    if (reservedEndTime) {
      result = [...result, moment(reservedEndTime, "HH:mm").format(timeFormat)];
    }

    if (reservedPlace) {
      result = [...result, reservedPlace];
    }

    return result.join(" ");
  }

  async toggleOpen() {
    const target = !this.open;

    try {
      this.loading = true;
      if (target && this.shouldLoadDependencies) {
        if (!this.issue.read) {
          const legacyIssue = await LegacyIssueApi.readIssue(this.issue.objectId);

          this.updateIssue({
            objectId: this.issue.objectId,
            issue: { ...this.issue, read: legacyIssue.isRead },
          });
        }

        const dependencies = [this.loadDetail(), this.loadReplies()];
        await Promise.all(dependencies);
      }

      this.open = target;
      this.shouldLoadDependencies = false;
    } catch (error) {
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  async loadDetail() {
    try {
      this.detail = await IssueApi.getIssue(this.hotelInfo.objectId as string, this.issue.objectId);
    } catch (error) {
      this.$message.error("이슈 상세 데이터를 불러오지 못했습니다.");
    }
  }

  async loadReplies() {
    try {
      await this.fetchIssueReply({
        hotelObjectId: this.hotelInfo.objectId as string,
        issueObjectId: this.issue.objectId,
      });
    } catch (error) {
      this.$message.error("이슈 댓글 목록을 불러오지 못했습니다.");
    }
  }

  async loadReadLogs() {
    try {
      this.readLogLoading = true;
      this.readLogs = await LegacyIssueApi.readLogList(this.issue.objectId);
    } catch (error) {
      this.$message.error("이슈 조회 데이터를 불러오지 못했습니다.");
    } finally {
      this.readLogLoading = false;
    }
  }

  async toggleChecklist(item: Record<string, unknown>) {
    const { objectId, content, checked } = item;

    try {
      this.loading = true;
      const legacyIssue = (await LegacyIssueApi.updateChecklist(
        this.issue.objectId,
        objectId as string,
        content as string,
        !checked,
      )) as Record<string, unknown>;

      this.detail = {
        ...(this.detail as IssueDetailDto),
        checklists: this.migrateLegacyTodosAsChecklists(
          legacyIssue.todos as Record<string, unknown>[],
        ),
      };
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  async toggleBookmark() {
    const { objectId, bookmarked } = this.issue;

    try {
      const legacyIssue = (await LegacyIssueApi.bookmark(objectId, !bookmarked)) as Record<
        string,
        unknown
      >;

      this.updateIssue({
        objectId,
        issue: { ...this.issue, bookmarked: legacyIssue.isBookmarked as boolean },
      });

      if (this.detail != null) {
        this.detail = {
          ...(this.detail as IssueDetailDto),
          bookmarked: legacyIssue.isBookmarked as boolean,
        };
      }
    } catch (error) {
      this.$message.error("북마크 상태를 변경하지 못했습니다.");
    }
  }

  async updateStatus() {
    try {
      this.loading = true;
      const legacyIssue = (await LegacyIssueApi.updateStatus(
        this.issue.objectId,
        (this.issue.status! + 1) % 4,
      )) as Record<string, unknown>;

      this.updateIssue({
        objectId: this.issue.objectId,
        issue: { ...this.issue, status: legacyIssue.status as number },
      });
    } catch (error) {
      this.$message.error("이슈 상태를 변경하지 못했습니다.");
    } finally {
      this.loading = false;
    }
  }

  async duplicateIssue() {
    try {
      this.loading = true;
      let dependencies: Promise<unknown>[] = [];

      if (this.detail == null) {
        dependencies.push(this.loadDetail());
      }

      if (this.readLogs.length === 0) {
        dependencies.push(this.loadReadLogs());
      }

      await Promise.all(dependencies);

      this.showModal({
        type: "write-issue-modal",
        transparent: true,
        maskClosable: false,
        props: {
          defaultData: {
            ...this.transformIssueTolegacyIssue(
              this.issue,
              this.detail as IssueDetailDto,
              this.readLogs,
            ),
            objectId: null,
          },
        },
        onOk: () => this.$emit("change"),
      });
    } finally {
      this.loading = false;
    }
  }

  async confirmUpdateIssue() {
    try {
      this.loading = true;
      let dependencies: Promise<unknown>[] = [];

      if (this.detail == null) {
        dependencies.push(this.loadDetail());
      }

      if (this.readLogs.length === 0) {
        dependencies.push(this.loadReadLogs());
      }

      await Promise.all(dependencies);

      this.showModal({
        type: "write-issue-modal",
        transparent: true,
        maskClosable: false,
        props: {
          defaultData: {
            ...this.transformIssueTolegacyIssue(
              this.issue,
              this.detail as IssueDetailDto,
              this.readLogs,
            ),
          },
        },
        onOk: () => this.$emit("change"),
      });
    } finally {
      this.loading = false;
    }
  }

  async confirmDeleteIssue() {
    try {
      this.loading = true;
      await LegacyIssueApi.deleteIssue(this.issue.objectId);
    } catch (error) {
      this.$message.error("이슈를 삭제하지 못했습니다.");
    } finally {
      this.loading = false;
    }
  }

  moveIssueToWork() {
    this.showModal({
      type: "select-work-category-modal",
      props: { data: { contents: this.issue.content } },
    });
  }

  showChangeLog() {
    const { objectId } = this.issue;
    this.showModal({
      type: "issue-change-log-modal",
      transparent: true,
      props: { objectId },
    });
  }

  transformChecklistsAsLegacyTodos(checklists: Record<string, unknown>[]) {
    return checklists.map((checklist) => ({
      objectId: checklist.objectId,
      contents: checklist.content,
      isDone: checklist.checked,
    }));
  }

  migrateLegacyTodosAsChecklists(todos: Record<string, unknown>[]) {
    return todos.map((todo) => ({
      objectId: todo.objectId,
      content: todo.contents,
      checked: todo.isDone,
    }));
  }

  transformFilesAsLegacyFiles(files: Record<string, unknown>[], users: Record<string, unknown>[]) {
    return files.map((file) => ({
      objectId: file.objectId,
      hotelObjectId: this.hotelInfo.objectId as string,
      targetType: "issue",
      targetObjectId: this.issue.objectId as string,
      writerObjectId: this.issue.writer?.objectId as string,
      url: file.fileURL,
      contents: this.issue.content,
      fileName: file.fileName,
      categoryName: this.issue.category,
      size: file.fileSize,
      relatedUserObjectIds: users.map((u) => u.objectId),
      createdTime: new Date().toISOString(),
    }));
  }

  transformIssueTolegacyIssue(
    issue: IssueDto,
    detail: IssueDetailDto,
    users: Record<string, unknown>[],
  ) {
    const { content, secret, category, teams, objectId } = issue;
    const {
      mentions,
      checklists,
      reservedStartDate,
      reservedEndDate,
      reservedStartTime,
      reservedEndTime,
      reservedPlace,
      allDay,
      files,
    } = detail;

    return {
      objectId,
      teams,
      mentions,
      category: category,
      contents: content,
      reservedStartDate,
      reservedEndDate,
      reservedStartTime,
      reservedEndTime,
      reservedPlace,
      isAllDay: allDay,
      isSecret: secret,
      todos: this.transformChecklistsAsLegacyTodos(checklists),
      files: this.transformFilesAsLegacyFiles(files, users),
    };
  }

  @Watch("issue", { deep: true })
  onIssueChange(_val: IssueDto) {
    if (this.detail != null) {
      this.loadDetail();
    }
  }
}
