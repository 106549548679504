import Client from "../client";

export class Popup {
  static async All() {
    return await Client.get("/popup");
  }

  static async Get(id: any) {
    return await Client.get(`/popup/${id}`);
  }

  static async Create(form: any) {
    return await Client.post("/popup", form);
  }

  static async Update(form: any) {
    return await Client.put("/popup", form);
  }

  static async Delete(id: any) {
    return await Client.del(`/popup/${id}`);
  }
}
