






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ProfileImage extends Vue {
  @Prop() profileImage!: string;

  get image() {
    return this.profileImage ?? this.defaultProfile;
  }

  get defaultProfile() {
    return require("/public/assets/components/default-profile.svg");
  }
}
