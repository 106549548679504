import Client from "../client";

export class Support {
  static async count({ startDate, endDate, hotelObjectId }: any) {
    return await Client.get<any>(`/support/count`, { startDate, endDate, hotelObjectId });
  }

  static async simpleHotels() {
    return await Client.get<any>(`/support/hotel/simple`);
  }

  static async hotels({ page, keyword, deleted }: any) {
    return await Client.get<any>(`/support/hotel`, { page, keyword, deleted });
  }

  static async deleteHotel(objectId: string) {
    return await Client.del<any>(`/support/hotel/${objectId}`);
  }

  static async recoveryHotel(objectId: string) {
    return await Client.post<any>(`/support/hotel/${objectId}/recovery`);
  }

  static async users({ page, keyword, hotelObjectId }: any) {
    return await Client.get<any>(`/support/user`, { page, keyword, hotelObjectId });
  }

  static async issues({ page, hotelObjectId }: any) {
    return await Client.get<any>(`/support/issue`, { page, hotelObjectId });
  }

  static async notices({ page, hotelObjectId }: any) {
    return await Client.get<any>(`/support/notice`, { page, hotelObjectId });
  }

  static async password(objectId: string, password: string) {
    return await Client.post<any>(`/support/password`, { objectId, password });
  }
}
