import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Auth, RegisterForm } from "@/http/api/auth";

const idRegex = /^\S{3,}$/;
const nameRegex = /^.{2,20}$/;
const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{6,20}$/;
const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const getRegisterFormDefault = (): RegisterForm => ({
  id: "",
  password: "",
  passwordConfirm: "",
  name: "",
  email: "",

  validateStatus: {
    id: "",
    password: "",
    passwordConfirm: "",
    name: "",
    email: "",
  },

  error: {
    id: "",
    password: "",
    passwordConfirm: "",
    name: "",
    email: "",
  },
});

@Module({
  namespaced: true,
})
export default class RegisterModule extends VuexModule {
  registerForm: RegisterForm = getRegisterFormDefault();
  selfInfo: any = {};

  @Mutation
  resetRegisterForm() {
    this.registerForm = getRegisterFormDefault();
  }

  @Mutation
  setSelfInfo(value: any) {
    this.selfInfo = value;
  }

  @Action
  async checkId() {
    const { id } = this.registerForm;

    if (!idRegex.test(id)) {
      this.registerForm.validateStatus.id = "warning";
      this.registerForm.error.id = "아이디는 공백없이 4글자 이상 입력해주세요.";
      return;
    }

    this.registerForm.validateStatus.id = "validating";
    try {
      await Auth.checkId(id);
      this.registerForm.validateStatus.id = "success";
      this.registerForm.error.id = "";
    } catch {
      this.registerForm.error.id = "이미 가입된 아이디 입니다.";
      this.registerForm.validateStatus.id = "error";
    }
  }

  @Action
  checkPassword() {
    const { password, passwordConfirm } = this.registerForm;

    if (!passwordRegex.test(password)) {
      this.registerForm.validateStatus.password = "warning";
      this.registerForm.error.password =
        "문자 + 숫자 + 특수문자가 포함 된 6자리 이상을 입력해주세요.";

      this.registerForm.validateStatus.passwordConfirm = "";
      this.registerForm.error.passwordConfirm = "";

      return;
    } else {
      this.registerForm.validateStatus.password = "success";
      this.registerForm.error.password = "";
    }

    if (password !== passwordConfirm) {
      this.registerForm.validateStatus.passwordConfirm = "warning";
      this.registerForm.error.passwordConfirm = "비밀번호가 일치하지 않습니다.";
    } else {
      this.registerForm.validateStatus.passwordConfirm = "success";
      this.registerForm.error.passwordConfirm = "";
    }
  }

  @Action
  checkName() {
    const { name } = this.registerForm;

    if (!nameRegex.test(name)) {
      this.registerForm.validateStatus.name = "warning";
      this.registerForm.error.name = "이름 2글자 이상 입력해주세요.";
      return;
    } else {
      this.registerForm.validateStatus.name = "success";
      this.registerForm.error.name = "";
    }
  }

  @Action
  async checkEmail() {
    const { email } = this.registerForm;

    if (!emailRegex.test(email)) {
      this.registerForm.validateStatus.email = "warning";
      this.registerForm.error.email = "이메일 양식을 확인해주세요.";
      return;
    } else {
      this.registerForm.validateStatus.email = "";
      this.registerForm.error.email = "";
    }

    this.registerForm.validateStatus.email = "validating";
    try {
      await Auth.checkEmail(email);
      this.registerForm.validateStatus.email = "success";
      this.registerForm.error.email = "";
    } catch {
      this.registerForm.error.email = "이미 가입된 이메일 입니다.";
      this.registerForm.validateStatus.email = "error";
    }
  }

  @Action
  async register() {
    const { id, password, name, email } = this.registerForm;
    await Auth.register({ id, password, name, email });
  }

  @Action
  async loadSelfInfo() {
    const info = await Auth.loadSelfInfo();
    this.context.commit("setSelfInfo", info);
  }
}
