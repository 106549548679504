































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ReadLog extends Vue {
  @Prop() color: any;
  @Prop({ required: true }) objectId!: string;
  @Prop({ default: () => [] }) teams!: Record<string, unknown>[];
  @Prop({ default: () => 0 }) totalRead!: number;
  @Prop({ required: true }) count!: any;
  @Prop({ default: () => [] }) readLogs!: Record<string, unknown>[];
  @Prop({ default: false }) loading!: boolean;

  open = false;

  load() {
    if (!this.loading && this.readLogs.length === 0) {
      this.$emit("load");
    }
  }
}
