





























































import { ExtendEventTarget } from "@/interfaces/ExtendEventTarget";
import { Vue, Component, Model, Emit } from "vue-property-decorator";

@Component({
  components: {},
})
export default class TermsOfUse extends Vue {}
