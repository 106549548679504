




import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Mutation, namespace } from "vuex-class";
import { jsonToHtml } from "@/utiles/contentsParser";

@Component({})
export default class IssueCard extends Vue {
  @Prop() contents: any;
  @Prop({ default: false }) ignoreNewLine!: boolean;

  get html() {
    return jsonToHtml(this.contents, this.ignoreNewLine);
  }
}
