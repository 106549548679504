import { VuexModule, Module, Mutation, Action, MutationAction } from "vuex-module-decorators";
import { Notification } from "@/http/api/notification";
import { File } from "@/http/api/file";
import { Mention } from "@/http/api/mention";
import { Bookmark } from "@/http/api/bookmark";
import moment from "moment";

@Module({
  namespaced: true,
})
export default class CollectionModule extends VuexModule<any> {
  notificationList: any[] = [];
  notificationCurrentPage = 1;

  fileList: any[] = [];
  fileCurrentPage = 1;

  mentionList: any[] = [];
  mentionCurrentPage = 1;

  bookmarkOrder = "";
  bookmarkList: any[] = [];
  bookmarkCurrentPage = 1;

  @MutationAction
  async loadNotifications(clear: boolean = false) {
    const currentPage = clear ? 1 : this.state.notificationCurrentPage + 1;
    const list = await Notification.list(currentPage);

    const notificationList = list.reduce(
      (result, item) =>
        result.some((_: any) => item.objectId === _.objectId) ? result : [...result, item],
      clear ? [] : this.state.notificationList,
    );

    notificationList.sort((a: any, b: any) =>
      moment(a.createdTime).isAfter(moment(b.createdTime)) ? -1 : 1,
    );

    return {
      notificationList,
      notificationCurrentPage: list.length > 0 ? currentPage + 1 : currentPage,
    };
  }

  @MutationAction
  async readNotification(objectId: string) {
    const item = await Notification.read(objectId);

    const notificationList = [item].reduce((result, item) => {
      const index = result.findIndex((_: any) => item.objectId === _.objectId);

      if (index !== -1) {
        result.splice(index, 1, item);
      } else {
        result = [...result, item];
      }

      return result;
    }, this.state.notificationList);

    notificationList.sort((a: any, b: any) =>
      moment(a.createdTime).isAfter(moment(b.createdTime)) ? -1 : 1,
    );

    return {
      notificationList,
    };
  }

  @MutationAction
  async loadFiles(value: any) {
    const { clear, keyword } = value;
    const currentPage = clear ? 1 : this.state.fileCurrentPage;
    const list = await File.list(currentPage, keyword);

    const fileList = list.reduce(
      (result, item) =>
        result.some((_: any) => item.objectId === _.objectId) ? result : [...result, item],
      clear ? [] : this.state.fileList,
    );

    fileList.sort((a: any, b: any) => (moment(a.createdTime).isAfter(b.createdTime) ? -1 : 1));

    return {
      fileList,
      fileCurrentPage: list.length > 0 ? currentPage + 1 : currentPage,
    };
  }

  @MutationAction
  async loadMentions(clear: boolean = false) {
    const currentPage = clear ? 1 : this.state.mentionCurrentPage;
    const list = await Mention.list(currentPage);

    const mentionList = list.reduce(
      (result, item) =>
        result.some((_: any) => item.objectId === _.objectId) ? result : [...result, item],
      clear ? [] : this.state.mentionList,
    );

    return {
      mentionList,
      mentionCurrentPage: list.length > 0 ? currentPage + 1 : currentPage,
    };
  }

  @MutationAction
  async loadBookmarks({ clear = false, order = "" }: { clear: boolean; order: string }) {
    const currentPage = clear ? 1 : this.state.bookmarkCurrentPage;
    const list = await Bookmark.list(currentPage, order);

    const bookmarkList = list.reduce(
      (result, item) =>
        result.some((_: any) => item.targetObjectId === _.targetObjectId)
          ? result
          : [item, ...result],
      clear ? [] : this.state.bookmarkList,
    );

    if (order === "target") {
      bookmarkList.sort((a: any, b: any) =>
        moment(a.targetCreatedTime).isAfter(moment(b.targetCreatedTime)) ? -1 : 1,
      );
    } else {
      bookmarkList.sort((a: any, b: any) =>
        moment(a.createdTime).isAfter(moment(b.createdTime)) ? 1 : -1,
      );
    }

    return {
      bookmarkOrder: order,
      bookmarkList,
      bookmarkCurrentPage: list.length > 0 ? currentPage + 1 : currentPage,
    };
  }
}
