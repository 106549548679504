
















import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Modal = namespace("modal");

@Component({
  components: {},
})
export default class Attachment extends Vue {
  @Prop() file: any;

  @Modal.Action("showModal") showModal: any;

  created() {}

  getExtension(path: string) {
    return (/[^.]+$/.exec(path) || [])[0].toLowerCase();
  }

  fileTypeImage(type: string) {
    switch (type) {
      case "xls":
      case "xlsm":
      case "xlsx":
      case "csv":
        return "/assets/attachment/xls.svg";
      case "ppt":
      case "pptx":
      case "pptm":
        return "/assets/attachment/ppt.svg";
      case "pdf":
        return "/assets/attachment/pdf.svg";
      case "docx":
      case "doc":
        return "/assets/attachment/doc.svg";
      case "hwp":
        return "/assets/attachment/hwp.svg";
      default:
        return "/assets/attachment/etc.svg";
    }
  }

  fileExt(fileName: string) {
    const result = fileName?.toLowerCase().split(".");
    return result && result[result.length - 1];
  }

  detailImage() {
    this.showModal({
      type: "image-detail-modal",
      props: {
        data: [this.file],
        initIndex: 0,
      },
    });
  }

  onDownloadFile(file: any) {
    const a = document.createElement("a");
    a.download = file.fileName;
    a.href = file.url;
    a.click();
  }

  imageExtensions = ["png", "jpeg", "jpg", "bmp", "gif", "bmp", "heif", "heic"];

  isImage(path: string) {
    const extension = this.getExtension(path);
    return this.imageExtensions.indexOf(extension) !== -1;
  }
}
