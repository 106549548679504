






import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, namespace } from "vuex-class";

const Modal = namespace("modal");

@Component({
  name: "logoPreviewModal",
})
export default class LogoPreviewModal extends Vue {
  @Prop() data: any;

  @Modal.State("modal") modal: any;
  @Action("modal/handleCancel") handleCancel: any;
  @Action("modal/handleOk") handleOk: any;
}
Vue.component("LogoPreviewModal", LogoPreviewModal);
