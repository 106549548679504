




















































































import Vue from "vue";
import issueCardTeam from "@/components/issues/issueCardTeam.vue";
import moment from "moment";
import Component from "vue-class-component";
import { State, Action, namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { NoticeChangeLog } from "@/http/api/noticeChangeLog";
import Attachment from "@/components/common/attachment.vue";

const NoticeModule = namespace("NoticeModule");
const MemberModule = namespace("MemberModule");
const Modal = namespace("modal");

@Component({
  name: "NoticeChangeLogModal",
  components: {
    issueCardTeam,
    Attachment,
  },
})
export default class NoticeChangeLogModal extends Vue {
  @Prop() objectId!: string;

  @NoticeModule.State("noticeDetail") noticeDetail: any;

  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  @Modal.State("modal") modal: any;
  @Modal.Action("handleOk") handleOk: any;
  @Modal.Action("handleCancel") handleCancel: any;

  moment: any = moment;

  public dataList: any[] = [];

  getImageStyle(value: string) {
    const url = encodeURI(value);

    return `background-image: url("${url}")`;
  }

  async created() {
    await this.loadMemberListAsync();
    const list = await NoticeChangeLog.list(this.objectId);
    this.dataList = list.reduce(this.groupedListReducer, []).map(this.groupedListMapper);
  }

  isUsedDetail(detail: any) {
    switch (detail.key) {
      case "BookmarkedMemberObjectIds":
        return false;
      case "SetTopTime":
        return false;
      case "TopStartTime":
      case "TopEndTime":
        if (!detail.newValue) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  isUsedDetailGroup(list: any[]) {
    const keys = list[0].details.map((detail: any) => detail.key);
    const filteredKeys = keys.filter(
      (key: string) => key != "BookmarkedMemberObjectIds" && key != "SetTopTime",
    );
    if (filteredKeys.length > 0) {
      return true;
    }
    return false;
  }

  groupedListMapper(item: any) {
    return {
      ...item,
      key: item.key,
      list: item.list.map((i: any) => {
        return { ...i, details: i.details.map(this.detailMapper) };
      }),
    };
  }

  getMember(memberObjectId: string) {
    return this.memberList.find((m: any) => m.objectId == memberObjectId).name;
  }

  detailMapper(detail: any) {
    if (detail) {
      const text = this.filter(detail);

      switch (detail.key) {
        case "Files":
        case "Teams":
        case "NewReplyImages":
          return {
            ...detail,
            text,
            newValue: JSON.parse(detail.newValue),
          };

        case "Anchors": {
          const anchorObjectIds = JSON.parse(detail.newValue);
          return {
            ...detail,
            text,
            newValue: this.memberList.filter((item: any) =>
              anchorObjectIds.includes(item.objectId),
            ),
          };
        }

        default:
          return {
            ...detail,
            text,
          };
      }
    }

    return detail;
  }

  filter(detail: any) {
    const { key, newValue, oldValue } = detail;

    switch (key) {
      case "Title":
        return "제목을 작성하였습니다.";

      case "Reply":
        return "댓글을 작성하였습니다.";

      case "Category":
        return `카테고리를 '${newValue}' 로 설정하였습니다.`;

      case "Contents":
        return "내용을 작성하였습니다.";

      case "Files":
      case "NewReplyFiles":
        return "파일을 등록하였습니다.";

      case "Teams":
        return "해당 부서를 선택하였습니다.";

      case "Anchors":
        return "해당 유저를 태그하였습니다.";

      case "Mentions":
        return "해당 유저를 태그하였습니다.";

      case "IsTop":
        return newValue == "True" ? `'상단게시'로 설정하였습니다.` : `'상단게시'를 해제하였습니다.`;

      case "TopStartTime":
        return newValue
          ? `상단게시 시작일을 '${moment(newValue)
              .format("YYYY/MM/DD")
              .toString()}'로 설정하였습니다.`
          : "";

      case "TopEndTime":
        return `상단게시 종료일을 '${moment(newValue)
          .format("YYYY/MM/DD")
          .toString()}'로 설정하였습니다.`;

      case "NewReplyContents":
        return "댓글을 작성하였습니다.";

      case "NewReplyImages":
        return "댓글에 파일을 추가하였습니다.";

      default:
        return "";
    }
  }

  groupedListReducer(groups: any[], log: any) {
    const key = moment(log.createdTime).format("YYYY/MM/DD dddd");
    const group = groups.find((g) => g.key === key);

    if (!group) {
      return [
        ...groups,
        {
          key,
          list: [log],
        },
      ];
    } else {
      group.list = [...group.list, log];
    }

    return groups;
  }
}

Vue.component("NoticeChangeLogModal", NoticeChangeLogModal);
