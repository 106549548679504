import Client from "../client";

export class Mention {
  static async list(currentPage: number) {
    return await Client.get<any[]>(`/mention`, { currentPage });
  }

  static async read(objectId: string) {
    return await Client.post<any>(`/mention/${objectId}/read`);
  }
}
