















































































































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import settingItem from "@/components/settings/settingItem.vue";
import moment from "moment";
import { Member } from "@/http/api/member";

const HotelModule = namespace("HotelModule");
const SettingModule = namespace("SettingModule");

@Component({
  components: {
    settingItem,
  },
})
export default class CommonSettings extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;
  @SettingModule.Action("loadSettingData") loadSettingData: any;
  @SettingModule.Action("updateSettingAsync") updateSettingAsync: any;

  activeKey = ["1", "2"];
  browser: string = "";
  rumyStartDate: string = "";
  hotelOwnerInfo: string = "";
  settingData: any = {};

  async created() {
    this.settingData = await this.loadSettingData();
    await this.loadInfoAsync();
    this.browser = navigator.userAgent.toLowerCase();
    this.rumyStartDate = moment(this.hotelInfo.createdTime).format("YYYY.MM.DD").toString();
    const owner = await Member.single(this.hotelInfo.ownerObjectId);
    this.hotelOwnerInfo = `${owner.name} (${owner.id})`;
  }

  async onChange(event: any, key: string) {
    const value = event.target.checked;
    const data = {
      key,
      value,
    };
    await this.updateSettingAsync(data);
    this.settingData = await this.loadSettingData();
  }
}
