import Client from "../client";

export class Notice {
  static async list(filter: any) {
    const { startDate, endDate, category, ...noticeFilter } = filter;

    return await Client.get<any[]>(`/notice`, {
      ...noticeFilter,
      categories: category ? [category] : [],
      startDate: startDate && startDate.format("YYYY-MM-DD"),
      endDate: endDate && endDate.format("YYYY-MM-DD"),
    });
  }

  static async topList() {
    return await Client.get<any[]>(`/notice/top`);
  }

  static async singleNotice(objectId: string) {
    return await Client.get(`/notice/${objectId}`);
  }

  static async createNotice(notice: any) {
    return await Client.put(`/notice`, notice);
  }

  static async updateNotice(notice: any) {
    return await Client.post(`/notice/${notice.objectId}`, notice);
  }

  static async readNotice(objectId: string) {
    return await Client.post<any>(`/notice/${objectId}/read`);
  }

  static async deleteNotice(objectId: string) {
    return await Client.del(`/notice/${objectId}`);
  }

  static async listCount(filter: any) {
    const { startDate, endDate, category, ...noticeFilter } = filter;
    return await Client.get(`/notice/count`, {
      ...noticeFilter,
      categories: category ? [category] : [],
      startDate: startDate && startDate.format("YYYY-MM-DD"),
      endDate: endDate && endDate.format("YYYY-MM-DD"),
    });
  }

  static async bookmarkNotice(objectId: string, bookmark: Boolean) {
    return await Client.post<any>(`/notice/${objectId}/bookmark`, { bookmark });
  }
}
