





































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Popup } from "@/http/api/popup";
import Summernote from "@/components/summernote.vue";
import { namespace } from "vuex-class";

const Modal = namespace("modal");

@Component({
  components: { Summernote },
})
export default class SupportPopup extends Vue {
  @Modal.Action("showModal") showModal: any;

  config: any = {
    height: 400,
    toolbar: [
      [
        "style",
        [
          "style",
          "fontname",
          "fontsize",
          "fontsizeunit",
          "bold",
          "italic",
          "strikethrough",
          "underline",
          "color",
          "clear",
          "imageUpload",
          "link",
          "paragraph",
          "height",
          "ul",
          "ol",
          "undo",
          "redo",
        ],
      ],
    ],
  };

  edit = false;
  loading = true;
  popups: any = [];

  form: any = {
    objectId: undefined,
    htmlContents: undefined,
  };

  mounted() {
    this.load();
  }

  async load() {
    this.loading = true;
    try {
      this.popups = await Popup.All();
      if (this.popups.length) {
        this.form = {
          objectId: this.popups[0].objectId,
          htmlContents: this.popups[0].htmlContents,
        };
      } else {
        this.form = {
          objectId: undefined,
          htmlContents: undefined,
        };
      }
    } catch {
    } finally {
      this.loading = false;
    }
  }

  imageUrl(fileUrl: string) {
    if (fileUrl.startsWith("http")) {
      return fileUrl;
    } else return `https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/${fileUrl}`;
  }

  code() {
    return (this.$refs.editor as any).code();
  }

  async cancel() {
    try {
      await this.load();
    } catch {
    } finally {
      this.edit = false;
    }
  }

  async savePopup() {
    try {
      if (this.form.objectId) {
        await Popup.Update(this.form);
      } else {
        await Popup.Create(this.form);
      }
    } catch {
    } finally {
      await this.load();
      this.edit = false;
    }
  }

  async deletePopup(id: any) {
    try {
      await Popup.Delete(id);
    } catch {
    } finally {
      await this.load();
      this.edit = false;
    }
  }

  async preview() {
    this.showModal({
      type: "notice-modal",
      maskClosable: false,
      props: { data: [this.form] },
    });
  }
}
