












import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import notificationTab from "@/components/layouts/notificationTab.vue";
import fileTab from "@/components/layouts/fileTab.vue";
import mentionTab from "@/components/layouts/mentionTab.vue";
import bookmarkTab from "@/components/layouts/bookmarkTab.vue";
import organizationTab from "@/components/layouts/organizationTab.vue";

@Component({
  components: {
    notificationTab,
    mentionTab,
    fileTab,
    bookmarkTab,
    organizationTab,
  },
})
export default class RightTab extends Vue {
  @Prop({ default: "" }) value: any;
  @Prop({ default: "" }) open: any;

  tabs = [
    { key: "notification", component: "notification-tab" },
    { key: "mention", component: "mention-tab" },
    { key: "bookmark", component: "bookmark-tab" },
    { key: "file", component: "file-tab" },
    { key: "team", component: "organization-tab" },
  ];

  get tab() {
    return this.tabs.find((item) => item.key === this.value);
  }

  mounted() {
    if (!this.value) {
      this.$emit("input", this.tabs[0].key);
    }
  }

  toggle() {
    this.$emit("toggle");
  }
}
