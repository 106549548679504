import Client from "@/http/client";
import { IssueDetailDto, IssueDto, IssueFilterParams, IssueSummaryDto } from "@/http/dto/issue";

export class Issue {
  static async getIssues(hotelObjectId: string, filter?: IssueFilterParams) {
    return await Client.get<IssueDto[]>(`/v2/${hotelObjectId}/issues`, {
      ...filter,
    });
  }

  static async getIssueSummaries(hotelObjectId: string, filter?: IssueFilterParams) {
    return await Client.get<IssueSummaryDto[]>(`/v2/${hotelObjectId}/issue-summaries`, {
      ...filter,
    });
  }

  static async getIssue(hotelObjectId: string, objectId: string) {
    return await Client.get<IssueDetailDto>(`/v2/${hotelObjectId}/issues/${objectId}`);
  }
}
