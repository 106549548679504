
























































































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { File } from "@/http/api/file";
import axios from "axios";
import { Member } from "@/http/api/member";

const maxFileSize = 1024 * 1024 * 20;

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");

interface FileData {
  fileName?: string;
  objectId?: string;
  url?: string;
  targetType: string;
  size?: number;
  contents?: string;
}

@Component({
  components: {},
})
export default class PrivacySettings extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;

  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;
  @MemberModule.Action("checkPassword") checkPassword: any;
  @MemberModule.Action("checkName") checkName: any;
  @MemberModule.Action("checkEmail") checkEmail: any;
  @MemberModule.Action("updatePrivacyAsync") updatePrivacyAsync: any;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  permissions = [
    { index: 1, label: "사용자" },
    { index: 2, label: "관리자" },
    { index: 3, label: "마스터" },
  ];

  get rules() {
    return {
      name: [
        {
          required: true,
          message: "이름을 입력해주세요.",
        },
        {
          trigger: "blur",
          validator: async (rule: any, value: any, callback: any) => {
            try {
              if (value !== this.origin.name) {
                await Member.checkUserName(value);
              }
              callback();
            } catch {
              callback(new Error("이미 사용중인 이름입니다."));
            }
          },
        },
      ],
      confirm: [
        {
          required: this.data.password,
          message: "비밀번호를 한 번 더 입력해주세요.",
        },
        {
          trigger: "blur",
          validator: async (rule: any, value: any, callback: any) => {
            if (value !== this.data.password) {
              callback(new Error("입력한 패스워드가 다릅니다."));
            }

            callback();
          },
        },
      ],
    };
  }

  loading: boolean = false;

  data: any = {};
  origin: any = {};

  get subscriptionTeam() {
    return (
      (this.hotelInfo &&
        this.hotelInfo.allTeamObjectIds &&
        this.hotelInfo.allTeamObjectIds.filter(
          (objectId: string) => objectId != this.hotelInfo.teamObjectId,
        )) ||
      []
    );
  }

  get permission() {
    return this.permissions.find((item) => item.index === this.data.permission) || {};
  }

  async mounted() {
    this.load();
  }

  async load() {
    await this.loadInfoAsync();

    const {
      userObjectId,
      userId,
      userPhone,
      userExtension,
      userRank,
      userEmail,
      userName,
      userPermission,
      teamObjectId,
      allTeamObjectIds,
      userProfile,
    } = this.hotelInfo;

    this.data = {
      objectId: userObjectId,
      id: userId,
      name: userName,
      phone: userPhone,
      extension: userExtension,
      rank: userRank,
      email: userEmail,
      prevEmail: userEmail,
      prevName: userName,
      permission: userPermission,
      teamObjectId: teamObjectId,
      teamObjectIds: allTeamObjectIds,
      profile: userProfile,
    };

    this.origin = { ...this.data };
  }

  async presignedRequest({
    file,
    onSuccess,
    onError,
  }: {
    file: any;
    onSuccess: any;
    onError: any;
  }) {
    try {
      if (file.size >= maxFileSize) {
        throw Error("Large Size");
      }
      const { presignedUrl, url } = await File.getPreSignedUrl(file.name);

      await axios.request({
        url: presignedUrl,
        method: "PUT",
        data: file,
        headers: { "Access-Control-Allow-Origin": "*" },
      });

      onSuccess({
        url,
        fileName: file.name,
        size: file.size,
      });
    } catch (err) {
      this.$message.error("파일의 용량이 20MB가 넘어 파일 업로드에 실패하였습니다.");
      onError(err);
    }
  }

  async handleFileChange(event: any) {
    switch (event.file.status) {
      case "uploading":
        break;

      case "done":
        {
          const { response } = event.file;
          this.data.profile = response.url;
          this.data.file = response;

          this.$message.success("파일 업로드에 성공하였습니다.");
        }
        break;

      case "error":
        this.$message.error("파일 업로드에 실패하였습니다.");
        break;
    }
  }

  getTeamName(teamObjectId: string) {
    const foundTeam = this.hotelInfo.teams.find((t: any) => t.objectId == teamObjectId);
    return foundTeam ? foundTeam.name : "미소속";
  }

  submit() {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      try {
        this.loading = true;

        await this.updatePrivacyAsync(this.data);

        this.$message.success("개인정보가 수정 되었습니다.");
        this.load();
      } catch {
        this.$message.error("개인정보 수정에 실패하였습니다.");
      } finally {
        this.loading = false;
      }
    });
  }
}
