import Client from "../client";

export class Member {
  static async list() {
    return await Client.get<any[]>(`/member/`);
  }

  static async all() {
    return await Client.get<any[]>(`/member/all`);
  }

  static async single(objectId: string) {
    return await Client.get<any>(`/member/${objectId}`);
  }

  static async update(member: any) {
    return await Client.post<any>(`/member/${member.objectId}`, member);
  }

  static async updateTeam(member: any, data: any) {
    return await Client.post<any>(`/member/team/${member.objectId}`, data);
  }

  static async create(createModel: any) {
    return await Client.put<any>(`/member`, createModel);
  }

  static async delete(member: any) {
    return await Client.del<any>(`/member/${member.objectId}`);
  }

  static async state(memberObjectId: string, state: string) {
    return await Client.post<any>(`/member/${memberObjectId}/state`, { state });
  }

  static async password(memberObjectId: string, password: string) {
    return await Client.post<any>(`/member/${memberObjectId}/password`, { password });
  }

  static async checkUserName(name: string) {
    return await Client.get(`/member/checkUserName`, {
      name,
    });
  }

  static async invite(url: string, objectId: string) {
    return await Client.post(`/member/invite`, {
      url,
      objectId,
    });
  }

  static async batchCreate(data: string) {
    return await Client.post(`/member/batchCreate`, { data });
  }

  static async changeOrder(changeOrder: string) {
    return await Client.put(`/member/changeOrder`, changeOrder);
  }
}
