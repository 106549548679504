import Client from "../client";

export class Settings {
  static async loadSettingDataAsync(key?: string) {
    if (!key) {
      return await Client.get<any>(`/settings`);
    } else {
      return await Client.get<any>(`/settings/${key}`);
    }
  }

  static async updateSettingDataAsync(key: string, value: any) {
    return await Client.post(`/settings/${key}`, { value: JSON.stringify(value) });
  }

  static async setHotelOrdersAsync(hotels: string[]) {
    return await Client.post(`/settings/hotelOrders`, { hotels });
  }
}
