















import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";

const HotelModule = namespace("HotelModule");

@Component({
  components: {},
})
export default class AdminMenu extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;

  menus = [
    {
      key: "admin-team",
      title: "부서 관리",
    },
    {
      key: "admin-user",
      title: "유저 관리",
    },
    {
      key: "admin-category",
      title: "카테고리 관리",
    },
    {
      key: "admin-hotel",
      title: "호텔 관리",
    },
  ];

  get activeKey() {
    return this.menus.findIndex((item: any) => {
      if (item.key !== this.$route.name) {
        return false;
      }

      if (
        item.params &&
        this.$route.params &&
        Object.keys(item.params).some((key: any) => item.params[key] !== this.$route.params[key])
      ) {
        return false;
      }

      return true;
    });
  }

  permission(key: string) {
    switch (key) {
      case "admin-category":
      case "admin-hotel":
        if (this.hotelInfo.userPermission == 3) {
          return true;
        }
        return false;

      default:
        return true;
    }
  }
}
