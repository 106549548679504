






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Mutation, Getter, namespace } from "vuex-class";
import { Auth } from "@/http/api/auth";

const HotelModule = namespace("HotelModule");

@Component({
  components: {},
})
export default class HotelHeader extends Vue {
  @Prop({ default: "" }) value: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("toHotelUrl") toHotelUrl: any;

  rightTabs = [
    {
      key: "notification",
      name: "알림",
      off: "/assets/components/notice-off.svg",
      on: "/assets/components/notice-on.svg",
    },
    {
      key: "mention",
      name: "멘션",
      off: "/assets/components/mention-off.svg",
      on: "/assets/components/mention-on.svg",
    },
    {
      key: "bookmark",
      name: "북마크",
      off: "/assets/components/bookmark-off.svg",
      on: "/assets/components/bookmark-on.svg",
    },
    {
      key: "file",
      name: "파일",
      off: "/assets/components/file-off.svg",
      on: "/assets/components/file-on.svg",
    },
    {
      key: "team",
      name: "조직도",
      off: "/assets/components/group-off.svg",
      on: "/assets/components/group-on.svg",
    },
  ];

  get name() {
    return this.hotelInfo?.userName ?? "이름없음";
  }

  get team() {
    return this.hotelInfo?.team ?? (this.hotelInfo.userPermission === 3 ? "마스터" : "미소속");
  }

  change(item: any) {
    this.$emit("change", item.key);
  }

  async logout() {
    try {
      await Auth.logout();
    } finally {
      this.toHotelUrl({});
    }
  }
}
