








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IssueCardTeam extends Vue {
  @Prop() teams: any;

  parsedTeams(stringifyTeam: string) {
    return Object.assign(stringifyTeam, "");
  }
}
