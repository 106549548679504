








import { Vue, Component } from "vue-property-decorator";
import AcceptTerms from "@/components/register/acceptTerms.vue";
import RegisterManagerForm from "@/components/register/registerManagerForm.vue";
import VerifyManagerPhone from "@/components/register/verifyManagerPhone.vue";

@Component({
  components: {
    AcceptTerms,
    RegisterManagerForm,
    VerifyManagerPhone,
  },
})
export default class Register extends Vue {
  screenIndex = 0;

  screens: any[] = [
    {
      header:
        "<span class='medium'>원활한 서비스 제공을 위해 약관내용에 동의</span><span class='thin'>해주세요.</span>",
      component: AcceptTerms,
    },
    {
      header: "마스터 회원가입을 위해 정보를 입력해주세요.",
      component: RegisterManagerForm,
    },
    {
      header: "마스터 회원가입을 위해 정보를 입력해주세요.",
      component: VerifyManagerPhone,
    },
  ];

  data: any = {
    id: "",
    name: "",
    password: "",
    email: "",
    emailLeading: "",
    emailTrailing: "",
    phone: "",
    code: "",
  };

  next() {
    this.screenIndex += 1;
  }
}
