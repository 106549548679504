






import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import SweetScroll from "sweet-scroll";

const scroller = new SweetScroll();

@Component
export default class ScrollUp extends Vue {
  scrollMove() {
    scroller.to(0);
  }
}
