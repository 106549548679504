
















import { Vue, Component, Model, Emit, Prop } from "vue-property-decorator";
import { ExtendEventTarget } from "@/interfaces/ExtendEventTarget";

@Component
export default class BaseTerm extends Vue {
  @Prop() header: any;
  @Prop() term: any;
  @Prop() value: any;

  onChange(e: any) {
    this.$emit("input", e.target.checked);
  }
}
