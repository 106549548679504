







































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";
import issueReplyInput from "@/components/issues/issueReplyInput.vue";
import { textToJson } from "@/utiles/contentsParser";
import issueReadonlyContents from "./issueReadonlyContents.vue";
import ProfileImage from "@/components/common/profileImage.vue";
import Attachment from "@/components/common/attachment.vue";
import { Reply } from "@/http/api/reply";

const HotelModule = namespace("HotelModule");
const IssueModule = namespace("IssueModule");
const Modal = namespace("modal");

@Component({
  name: "IssueReply",
  components: {
    issueReplyInput,
    issueReadonlyContents,
    ProfileImage,
    Attachment,
  },
})
export default class IssueReply extends Vue {
  @Prop() issueObjectId: any;
  @Prop() parentObjectId: any;
  @Prop() data: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;

  @IssueModule.Action("loadIssue") loadIssue: any;
  @IssueModule.Action("loadIssueReplies") loadIssueReplies: any;

  @Modal.Action("showModal") showModal: any;

  moment: any = moment;

  edit: boolean = false;
  reply: boolean = false;

  get parentObjectIds() {
    return [this.issueObjectId, ...(this.parentObjectId ? [this.parentObjectId] : [])].join(",");
  }

  get editPermission() {
    return (
      this.hotelInfo &&
      (this.data.writerObjectId === this.hotelInfo.userObjectId ||
        this.hotelInfo.userPermission === 3)
    );
  }

  get deletePermission() {
    return (
      this.hotelInfo &&
      (this.data.writerObjectId === this.hotelInfo.userObjectId ||
        this.hotelInfo.userPermission === 3 ||
        this.hotelInfo.userPermission === 2)
    );
  }

  get contents() {
    return textToJson([], this.data.contents);
  }

  async confirmDeleteReply() {
    const { parentObjectIds } = this.data;
    const objectId = this.data.objectId;
    const rootObjectId = parentObjectIds.split(",")[0];

    await Reply.deleteReply(parentObjectIds, objectId);
    this.$message.error("댓글이 삭제되었습니다.");

    this.$emit("upsert");
  }

  upsert(reply: boolean = false) {
    if (reply) {
      this.reply = false;
    }

    this.$emit("upsert");
  }
}
