
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import IssueCardLegacy from "@/components/issues/issueCardLegacy.vue";
import { Issue } from "@/http/api/issue";
import _ from "lodash";

@Component({
  components: {
    IssueCardLegacy,
  },
})
export default class IssueDetail extends Vue {
  data: null = null;

  @Watch("$route", { deep: true })
  routeChaned(n: any, o: any) {
    const oldValue = o.params.objectId;
    const newValue = n.params.objectId;

    if (oldValue !== newValue) {
      this.load(newValue);
    }
  }

  mounted() {
    const objectId = this.$route.params.objectId;
    this.load(objectId);
  }

  async load(objectId: string) {
    try {
      this.data = await Issue.singleIssue(objectId);
    } catch {
      this.$router.replace({ name: "issue" });
    }
  }
}
