


























import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import moment from "moment";
import { fileName, fileUrl, icon, tagStyle, tagText } from "@/constants";

@Component
export default class FileTabCard extends Vue {
  @Prop() data: any;

  get fromNow() {
    return moment(this.data.createdTime).fromNow();
  }

  get profileStyle() {
    const url = this.data.writerProfile || "/assets/settings/default-user.svg";
    return {
      backgroundImage: `url('${url}')`,
    };
  }

  get fileStyle() {
    const url = icon(this.data.url);
    return {
      backgroundImage: `url('${url}')`,
    };
  }

  get tagStyle() {
    return tagStyle(this.data.targetType);
  }

  get tagText() {
    return tagText(this.data.targetType);
  }

  get writerName() {
    return this.data.writerName || "이름없음";
  }

  get writerTeam() {
    return this.data.writerTeam || "미소속";
  }

  get contents() {
    return this.data.fileName || fileName(this.data.fileName) || "이름없음";
  }

  click() {
    const a = document.createElement("a");
    a.target = "_blank";
    a.download = this.data.fileName;
    a.href = fileUrl(this.data.url);
    a.click();
  }
}
