





















import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, namespace } from "vuex-class";

const Modal = namespace("modal");
const WorkModule = namespace("WorkModule");

@Component({
  name: "selectWorkCategoryModal",
})
export default class SelectWorkCategoryModal extends Vue {
  @Prop() data: any;

  @Modal.State("modal") modal: any;
  @Action("modal/handleCancel") handleCancel: any;
  @Action("modal/handleOk") handleOk: any;
  @WorkModule.State("work") work: any;
  @WorkModule.Action("createWorkCard") createWorkCard: any;

  selectedRadio: string = "";

  created() {}

  mounted() {
    this.selectedRadio = this.work.categories[0].objectId;
  }

  onSave() {
    this.createWorkCard({ categoryId: this.selectedRadio, subject: this.data.contents });
    this.$message.success("이슈를 내 업무에 추가하였습니다.");
    this.handleOk();
  }

  get workCategories() {
    return this.work.categories;
  }
}
Vue.component("SelectWorkCategoryModal", SelectWorkCategoryModal);
