
















































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import fixedButtonContainer from "@/components/basic/fixedButtonContainer.vue";
import issueWriteButton from "@/components/issues/issueWriteButton.vue";
import ScrollUp from "@/components/basic/scrollUp.vue";
import IssueCard from "@/components/issues/issueCard.vue";
import IssueCardLegacy from "@/components/issues/issueCardLegacy.vue";
import IssueFilter from "@/components/issues/issueFilter.vue";
// import _ from "lodash";
import moment from "moment";
import { midnightTimer } from "@/utiles/timer";

// V2 imports
import IssueV2ModuleClass from "@/store/modules/v2/issueV2Module";
import Observer from "@/components/observer.vue";

const Modal = namespace("modal");
const IssueModule = namespace("IssueModule");
const HotelModule = namespace("HotelModule");

// V2 Sources
const IssueV2Module = namespace("IssueV2Module");

@Component({
  components: {
    IssueCard,
    IssueFilter,
    IssueCardLegacy,
    ScrollUp,
    issueWriteButton,
    fixedButtonContainer,
    Observer,
  },
})
export default class Issue extends Vue {
  @Modal.Action("showModal") showModal: any;

  @IssueModule.State("issueMap") issueMap: any;
  @IssueModule.State("groupedIssueList") groupedIssueList: any;
  @IssueModule.Action("loadIssueList") loadIssueList: any;
  @IssueModule.Action("loadReservePeriodList") loadReservePeriodList: any;

  @HotelModule.State("hotelInfo") hotelInfo!: Record<string, unknown>;

  // V2 Modules
  @IssueV2Module.State("issues")
  issues!: IssueV2ModuleClass["issues"];

  @IssueV2Module.State("end")
  end!: IssueV2ModuleClass["end"];

  @IssueV2Module.State("error")
  error!: IssueV2ModuleClass["error"];

  @IssueV2Module.State("loading")
  loading!: IssueV2ModuleClass["loading"];

  @IssueV2Module.Getter("initial")
  initial!: IssueV2ModuleClass["initial"];

  @IssueV2Module.Getter("groupedIssuesByDate")
  groupedIssuesByDate!: IssueV2ModuleClass["groupedIssuesByDate"];

  @IssueV2Module.Action("fetchIssues")
  fetchIssues!: IssueV2ModuleClass["fetchIssues"];

  @IssueV2Module.Action("refreshPage")
  refreshPage!: IssueV2ModuleClass["refreshPage"];

  @IssueV2Module.Action("nextPage")
  nextPage!: IssueV2ModuleClass["nextPage"];

  filter: any = {
    isNew: false,
    isReserved: false,
    isMine: false,
    status: [],
    keyword: "",
    keywordType: "contents",
    teamObjectIds: [],
    category: [],
    startDate: null,
    endDate: null,
  };

  // show: boolean = false;

  mounted() {
    this.initialize();
    // midnightTimer(this.loadReservedIssues, 10, 60000);
  }

  // async load(clear: boolean = true) {
  //   try {
  //     this.loading = true;
  //     await this.loadIssueList({ filter: this.filter, clear });
  //   } catch {
  //   } finally {
  //     this.loading = false;
  //     this.show = true;
  //   }
  // }

  onSearch() {
    // this.load();
  }

  write() {
    this.showModal({
      type: "write-issue-modal",
      transparent: true,
      maskClosable: false,
    });
  }

  // handleScroll() {
  //   const scrollTop =
  //     (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
  //   const bottomOfWindow = scrollTop + window.innerHeight;

  //   if (document.body.scrollHeight - bottomOfWindow < 150) {
  //     this.load(false);
  //   }
  // }

  // async loadReservedIssues() {
  //   await this.loadReservePeriodList({
  //     ...this.filter,
  //     categories: this.filter.category ? [this.filter.category] : [],
  //     start: moment().format("YYYY-MM-DD"),
  //     end: moment().add(1, "days").format("YYYY-MM-DD"),
  //   });
  // }

  // throttle = _.throttle(this.handleScroll, 500);

  // V2 Methods
  initialize() {
    if (this.initial) {
      this.fetchIssues({ hotelObjectId: this.hotelInfo.objectId as string });
    }
  }

  refresh() {
    this.refreshPage({
      hotelObjectId: this.hotelInfo.objectId as string,
      filter: {
        keyword: this.filter.keyword,
        from: this.filter.startDate?.format("YYYY-MM-DD"),
        to: this.filter.endDate?.format("YYYY-MM-DD"),
        mine: this.filter.isMine,
        new: this.filter.isNew,
        keywordType: this.filter.keywordType,
        teams: this.filter.teamObjectIds,
        categories: this.filter.category,
        statuses: this.filter.status,
      },
    });
  }

  next() {
    this.nextPage({ hotelObjectId: this.hotelInfo.objectId as string });
  }

  migrateOldFilterAsV2Filter(filter: Record<string, unknown>) {}
}
