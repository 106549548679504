






















































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import privacy from "@/pages/hotel/settings/privacy.vue";
import common from "@/pages/hotel/settings/common.vue";
import hotelList from "@/pages/hotel/settings/hotelList.vue";
import favorite from "@/pages/hotel/settings/favorite.vue";
import helpCenter from "@/pages/hotel/settings/helpCenter.vue";

const HotelModule = namespace("HotelModule");
const Modal = namespace("modal");

@Component({
  components: {
    privacy,
    common,
    hotelList,
    favorite,
    helpCenter,
  },
})
export default class Settings extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;

  @Modal.Action("showModal") showModal: any;
  @Modal.Action("handleCancel") handleCancel: any;
  @Modal.State("notice") notice: any;

  selectedTab: number = 0;

  async created() {
    await this.loadInfoAsync();
  }

  changeTab(tabNumber: number) {
    this.selectedTab = tabNumber;
  }
}
