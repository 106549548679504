










import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import organizationTree from "@/components/layouts/organizationTree.vue";


@Component({
  components: {
    organizationTree,
  },
})
export default class OrganizationTab extends Vue {}
